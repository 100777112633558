import React, { useState, useEffect } from 'react';
import classes from './Gallery.module.css';
import RegLogo from '../../Images/RegistrationLogo.svg'
import { Spinner, Badge, Button } from 'react-bootstrap';
import Folder from '../../Images/folder-2.svg';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import ArrowLogo from '../../Images/arrow-left.svg';
import LoanImage from '../../Images/loan bg.svg';
import MainDashoard from '../Main Dashboard/MainDashoard';
import Ready from '../../Images/nothing.svg'
import Ready1 from '../../Images/review.svg';
import { Link, useNavigate } from 'react-router-dom'
import Table from 'react-bootstrap/Table';
import { BASE_URL } from '../Api/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';

function ApprovalBadge({ status }) {
  let badgeProps = {};

  switch (status) {
      case "0":
          badgeProps = { text: "Pending", variant: "warning" };
          break;
      case "1":
          badgeProps = { text: "Awaiting Final Approval", variant: "info" };
          break;
      case "2":
          badgeProps = { text: "Approved", variant: "success" };
          break;
      case "3":
          badgeProps = { text: "Disapproved", variant: "danger" };
          break;
      default:
          badgeProps = { text: "Unknown", variant: "secondary" };
          break;
  }

  return <Badge bg={badgeProps.variant}>{badgeProps.text}</Badge>;
}

export default function Gallery() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [loadings, setLoadings] = useState(false);
  const [editClicked, setEditClicked] = useState(false);
  const [viewClicked, setViewClicked] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [totalEntries, setTotalEntries] = useState("");
  const [bearer, setBearer] = useState('');
  const [user, setUser] = useState('');
  const [allGallery, setAllGallery] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [trashClicked, setTrashClicked] = useState(false);
  const [roleId, setRoleId] = useState('');

    const readData = async () => {
      try {
        const details = await AsyncStorage.getItem('userToken');
        const users = await AsyncStorage.getItem('fullName');
        const detailss = await AsyncStorage.getItem('roleId');    
        
        if (details !== null) {
          setBearer(details);
        }
        if (users !== null) {
          setUser(users);
        }
        if (detailss !== null) {
          setRoleId(detailss);
      }
  
      } catch (e) {
        alert('Failed to fetch the input from storage');
      }
    };
  
    useEffect(() => {
      readData();
    }, []);

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${bearer}`
    };


    const EditGallery = () =>{
        navigate('/edit_gallery')
    }
    const addNew = () =>{
        navigate('/new_gallery')
    }


    const fetchAllGallery = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${BASE_URL}/media/view-photos?page=${currentPage}`, { headers });
        const results = response.data?.data?.data;
        const resultx = response.data?.data?.total;
        setTotalEntries(resultx);
        setTableData(results);
        const total = response.data?.data?.last_page || 1;
        setTotalPages(total);
        console.log(results, "FSHFKLSHFKLSHD");
      } catch (error) {
        if (error.response && error.response.status === 401) {
          // Redirect to login page if unauthorized
          navigate('/login');
        } else {
          const errorStatus = error.response?.data?.message;
          console.log(errorStatus);
          setTableData([]);
        }
      } finally {
        setIsLoading(false);
      }
    };
  useEffect(() => {
      if (bearer) {
          fetchAllGallery();
      }
    }, [bearer, currentPage]);

    const handleEditClick = (id) => {
      const foundGallery = tableData.find(item => item.id === id);
       console.log(foundGallery);
      navigate('/edit_gallery', { state: { selectedGallery: foundGallery } });
      setEditClicked(true);
    };

    const handleGalleryCheck = (id) => {
      const foundGallery = tableData.find(item => item.id === id);
       console.log(foundGallery);
      navigate('/view_gallery', { state: { selectedGallery: foundGallery } });
      setViewClicked(true);
    };

      const handleTrashClick = async (id) => {
      try {
          const confirmed = await Swal.fire({
              title: 'Are you sure?',
              text: 'You are about to delete this photo gallery',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes, delete it!',
              cancelButtonText: 'No, cancel',
            });
    
        if (!confirmed.isConfirmed) {
          return; 
        }
    
        const response = await axios.get(`${BASE_URL}/media/delete?id=${id}`, { headers });
        fetchAllGallery();
        toast.success(response.data.message);
      } catch (error) {
        let errorMessage = 'An error occurred. Please try again.';
        
        if (error.response?.data?.message) {
          if (typeof error.response.data.message === 'string') {
            errorMessage = error.response.data.message;
          } else if (Array.isArray(error.response.data.message)) {
            errorMessage = error.response.data.message.join('; ');
          } else if (typeof error.response.data.message === 'object') {
            errorMessage = JSON.stringify(error.response.data.message);
          }
        }
    
        toast.error(errorMessage);
      }
    };

    const handleNextPage = () => {
      if (currentPage < totalPages) {
          setCurrentPage(prevPage => prevPage + 1);
      }
  };

  const handlePrevPage = () => {
      if (currentPage > 1) {
          setCurrentPage(prevPage => prevPage - 1);
      }
  };


  function formatDate(dateString) {
    const date = new Date(dateString);
    const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())} ${padZero(date.getHours())}:${padZero(date.getMinutes())} ${date.getHours() >= 12 ? 'PM' : 'AM'}`;
    return formattedDate;
  }

  function padZero(num) {
    return num < 10 ? `0${num}` : num;
  }



const renderApprovalBadge = (status) => <ApprovalBadge status={status} />;
    return (
        <div>
        <MainDashoard />

        <div className={classes.finishedbodyCont}>
            <div className={classes.topPadding}>
                <div className={`${classes.formSecCont}`}>
                    <div className={classes.formSectionHeader}>
                        <h3>Photo Gallery</h3>
                    </div>
                    <div className={classes.formSectionHeader}>
                        {/* <small>Welcome </small> */}
                        <h3 style={{color:'#2D995F'}}>{user}</h3>
                    </div>
                </div>
            </div>
            

        <div className={classes.mainform}>

    <div className={classes.loandgrantcards}>
    {/* <div className={classes.loandethead}>
        <p className={classes.loanText}>Post news on the website from the editor</p>
        <Button variant="light" onClick={addNews}><i class='bx bx-plus-circle'></i> Add New</Button>
    </div> */}
    <div className={classes.loandethead}>
        <div className={classes.formLabel}>
            {/* <h4 style={{color:'#2D995F'}}>Gallery</h4> */}
            {/* <p className={classes.loanText}>Home - Apps - eCommerce - Catalog</p> */}
        </div>
        <div className={classes.formIntBtn}>
            {/* <Button variant="light" className={classes.btn1}> Add Member</Button> */}
            <Button variant="success" onClick={addNew} className={classes.btn2}>Add New</Button>
        </div>
    </div>
    {isLoading ? (
        <small>Fetching Gallery...</small>
    ) : (

    <div className={classes.loanContainer}>
        <div className={classes.loanResponsive}>
        <Table striped bordered hover>
      <thead>
        <tr>
        <th>S/N</th>
          <th>Title</th>
          {/* <th>Type</th> */}
          <th>Description</th>
          <th>Approval Status</th>
          <th>Approved/Disapproved By</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
      {tableData.map((item, index) => (
        <tr key={index}>
          <td>{index + 1}</td>
            <td >{item.title}</td>
            {/* <td></td> */}
            <td>{item.description}</td>
            <td>{renderApprovalBadge(item.approval_status)}</td>
            <td>
  {item.approval_status === "2" 
    ? item.approved_by 
    : item.approval_status === "3" 
    ? item.disapproved_by 
    : null}
</td>
            <td className={classes.edit}>
                <div style={{display:'flex', gap:'10px' }}>
                {(item.approval_status === "0" || item.approval_status === "3") && !["13", "14"].includes(roleId) ? (
                <div onClick={() => handleEditClick(item.id)}>
                    <i class='bx bx-edit-alt' ></i>
                    </div>
                    ) : null}
                    <div onClick={() => handleGalleryCheck(item.id)}>
                    <i class='bx bx-show-alt' ></i>
                    </div>
                    <div onClick={() => handleTrashClick(item.id)}>
                    <i class='bx bxs-trash' style={{color:'red'}}></i>
                    </div>
                    </div>
          </td>
        </tr>
        // <tr>
        //   <td>1</td>
        //   <td>Ogun state commence SME and Grants Scheme to empower 1000 SMEs in Ogun state.</td>
        //   <td>12th Apr, 2024</td>
        //   <td>Abiodun Moyo</td>
        //   <td className={classes.edit}>
        //     <div style={{display:'flex', gap:'10px'}}>
        //       <i class='bx bx-edit-alt' onClick={handleEdit}></i> 
        //       <i class='bx bx-show-alt' ></i> 
        //       <i class='bx bxs-trash' style={{color:'red'}}></i>  
        //     </div>
        //   </td>
        // </tr>
        // <tr>
        //   <td>1</td>
        //   <td>Ogun state commence SME and Grants Scheme to empower 1000 SMEs in Ogun state.</td>
        //   <td>12th Apr, 2024</td>
        //   <td>Abiodun Moyo</td>
        //   <td className={classes.edit}>
        //     <div style={{display:'flex', gap:'10px'}}>
        //       <i class='bx bx-edit-alt' onClick={handleEdit}></i> 
        //       <i class='bx bx-show-alt' ></i> 
        //       <i class='bx bxs-trash' style={{color:'red'}}></i>  
        //     </div>
        //   </td>
        // </tr>
        // <tr>
        //   <td>1</td>
        //   <td>Ogun state commence SME and Grants Scheme to empower 1000 SMEs in Ogun state.</td>
        //   <td>12th Apr, 2024</td>
        //   <td>Abiodun Moyo</td>
        //   <td className={classes.edit}>
        //     <div style={{display:'flex', gap:'10px'}}>
        //       <i class='bx bx-edit-alt' onClick={handleEdit}></i> 
        //       <i class='bx bx-show-alt' ></i> 
        //       <i class='bx bxs-trash' style={{color:'red'}}></i>  
        //     </div>
        //   </td>
        // </tr>
        // <tr>
        //   <td>1</td>
        //   <td>Ogun state commence SME and Grants Scheme to empower 1000 SMEs in Ogun state.</td>
        //   <td>12th Apr, 2024</td>
        //   <td>Abiodun Moyo</td>
        //   <td className={classes.edit}>
        //     <div style={{display:'flex', gap:'10px'}}>
        //       <i class='bx bx-edit-alt' onClick={handleEdit}></i> 
        //       <i class='bx bx-show-alt' ></i> 
        //       <i class='bx bxs-trash' style={{color:'red'}}></i>  
        //     </div>
        //   </td>
        // </tr>
        // <tr>
        //   <td>1</td>
        //   <td>Ogun state commence SME and Grants Scheme to empower 1000 SMEs in Ogun state.</td>
        //   <td>12th Apr, 2024</td>
        //   <td>Abiodun Moyo</td>
        //   <td className={classes.edit}>
        //     <div style={{display:'flex', gap:'10px'}}>
        //       <i class='bx bx-edit-alt' onClick={handleEdit}></i> 
        //       <i class='bx bx-show-alt' ></i> 
        //       <i class='bx bxs-trash' style={{color:'red'}}></i>  
        //     </div>
        //   </td>
        // </tr>
        // <tr>
        //   <td>1</td>
        //   <td>Ogun state commence SME and Grants Scheme to empower 1000 SMEs in Ogun state.</td>
        //   <td>12th Apr, 2024</td>
        //   <td>Abiodun Moyo</td>
        //   <td className={classes.edit}>
        //     <div style={{display:'flex', gap:'10px'}}>
        //       <i class='bx bx-edit-alt' onClick={handleEdit}></i> 
        //       <i class='bx bx-show-alt' ></i> 
        //       <i class='bx bxs-trash' style={{color:'red'}}></i>  
        //     </div>
        //   </td>
        // </tr>
        ))}
      </tbody>
    </Table>
    <div className={classes.accrodBtns}>
                    <Button onClick={handlePrevPage} variant='light' className={classes.prev}>Previous</Button>
                    <p style={{ color: '#2D995F' }}>{currentPage} of {totalPages}</p>
                    <Button onClick={handleNextPage} variant="success" className={classes.next}>Next</Button>
                  </div>
        {/* <table>
            <thead className={classes.loanTable}>
                <tr >
                    <th className={classes.tableText}>S/N</th>
                    <th className={classes.tableText}>TITLE</th>
                    <th className={classes.tableText}>DATE POSTED</th>
                    <th className={classes.tableText}>AUTHOR</th>
                    <th className={classes.tableText}>ACTION</th>
                    
                </tr>
            </thead> */}
            {/*                     
                            {isLoading ? (
                <p className={classes.fetchText}><Spinner size='sm' style={{ marginRight: 5 }} />Fetching grant application...</p>
            ) : grantDetail.length > 0 ? (
                <tbody>
                    <tr>
                        <td>S/N</td>
                        <td>Ogun state commence SME and Grants Scheme to empower 1000 SMEs in Ogun state.</td>
                        <td>12th Apr, 2024</td>
                        <td>Abiodun Moyo</td>
                        <td>Edit</td>
                    </tr>
                    <tr>
                        <td>S/N</td>
                        <td>Ogun state commence SME and Grants Scheme to empower 1000 SMEs in Ogun state.</td>
                        <td>12th Apr, 2024</td>
                        <td>Abiodun Moyo</td>
                        <td>Edit</td>
                    </tr>
                    <tr>
                        <td>S/N</td>
                        <td>Ogun state commence SME and Grants Scheme to empower 1000 SMEs in Ogun state.</td>
                        <td>12th Apr, 2024</td>
                        <td>Abiodun Moyo</td>
                        <td>Edit</td>
                    </tr>
                    
                    {grantDetail.map((item, index) => (
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item.application_number}</td>
                            <td>{item.type === 2 ? "Grant Application" : "Loan Application"}</td>
                            <td style={{ textAlign: "right" }}>₦{parseFloat(item.amount).toLocaleString('en-US', {
                                minimumIntegerDigits: 1,
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            })}</td>
                            <td>{formatDate(item.created_at)}</td>
                            <td>
                                <Badge bg={item.status === "Pending" ? 'warning' : item.status === "Approved" ? 'success' : 'danger'}>
                                    {item.status}
                                </Badge>
                            </td>
                            <td>
                            
                            </td>
                        </tr> 
                    ))} 
                </tbody>
            ) : (
                <tbody>
                    <tr>
                        <td colSpan="7">No grant applications</td>
                    </tr>
                </tbody>
            )}

            </table> */}

            
        </div>
    </div>
    )}
</div> 

</div>

        </div>

    </div>
)
}