import React, { useState, useEffect } from 'react';
import classes from './Gallery.module.css';
import RegLogo from '../../Images/RegistrationLogo.svg'
import { Spinner, Badge, Button } from 'react-bootstrap';
import Folder from '../../Images/folder-2.svg';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import ArrowLogo from '../../Images/arrow-left.svg';
import LoanImage from '../../Images/loan bg.svg';
import MainDashoard from '../Main Dashboard/MainDashoard';
import Ready from '../../Images/nothing.svg'
import Ready1 from '../../Images/review.svg';
import { Link, useNavigate } from 'react-router-dom'
import Table from 'react-bootstrap/Table';
import { BASE_URL } from '../Api/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';


export default function Contracts() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loadings, setLoadings] = useState(false);
  const [editClicked, setEditClicked] = useState(false);
  const [viewClicked, setViewClicked] = useState(false);
  const [bearer, setBearer] = useState('');
  const [user, setUser] = useState('');
  const [allNews, setAllNews] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [trashClicked, setTrashClicked] = useState(false);
  const [totalEntries, setTotalEntries] = useState("");
  const [tableData, setTableData] = useState([]);



  const readData = async () => {
    try {
      const details = await AsyncStorage.getItem('userToken');
      const users = await AsyncStorage.getItem('fullName');
             
      if (details !== null) {
        setBearer(details);
      }
      if (users !== null) {
        setUser(users);
      }

    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  useEffect(() => {
    readData();
  }, []);



const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };

// const EditNews = () =>{
//     navigate('/edit_news')
// } 
// const addNews = () =>{
//     navigate('/create_news')
// }

const fetchAllAdverts = async () => {
        setLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/fetch-mda-archive?page=${currentPage}`, { headers });
      const result = response.data?.data?.data;
    const resultx = response.data?.data?.total;
    setTotalEntries(resultx);
    setTableData(result);
    const total = response.data?.data?.last_page || 1;
    setTotalPages(total);
    console.log(result, 'Results here')
  
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
      const errorStatus = error.response?.data?.message;
      console.log(errorStatus);
      setTableData([]);
    }
    } finally {
        setLoading(false);
    }
  };


useEffect(() => {
    if (bearer) {
      fetchAllAdverts();
    }
  }, [bearer, currentPage]);

  const handleEditClick = (id) => {
    const foundContract = tableData.find(item => item.id === id);
     console.log(foundContract);
    navigate('/edit_contract', { state: { selectedContract: foundContract } });
    setEditClicked(true);
  };

  const handleContractCheck = (id) => {
    const foundContracts = tableData.find(item => item.id === id);
     console.log(foundContracts);
    navigate('/view_contract', { state: { selectedContracts: foundContracts}});
    setViewClicked(true);
  };

  const handleTrashClick = async (id) => {
    try {
        const confirmed = await Swal.fire({
            title: 'Are you sure?',
            text: 'You are about to delete this document',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel',
          });
  
      if (!confirmed.isConfirmed) {
        return; 
      }
          
     const response = await axios.get(`${BASE_URL}/delete-mda-archive?id=${id}`, { headers });
      fetchAllAdverts();
      setTrashClicked(true);
      toast.success(response.data.message);
    } catch (error) {
        let errorMessage = 'An error occurred. Please try again.';
        if (error.response && error.response.data && error.response.data.message) {
            if (typeof error.response.data.message === 'string') {
                errorMessage = error.response.data.message;
            } else if (Array.isArray(error.response.data.message)) {
                errorMessage = error.response.data.message.join('; ');
            } else if (typeof error.response.data.message === 'object') {
                errorMessage = JSON.stringify(error.response.data.message);
            }
        }
        toast.error(errorMessage);
        console.log(errorMessage);
    }
  };

    const handleEdit = () =>{
        navigate('/edit_contract');
    }
    const addNew = () =>{
        navigate('/new_contract');
    }
    
    const handlePrevPage = () => {
        setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
      };
      
      const handleNextPage = () => {
        setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
      };

    return (
        <div>
        <MainDashoard />

        <div className={classes.finishedbodyCont}>
            <div className={classes.topPadding}>
                <div className={`${classes.formSecCont}`}>
                    <div className={classes.formSectionHeader}>
                        <h3>Public Documents</h3>
                    </div>
                    <div className={classes.formSectionHeader}>
                        {/* <small>Welcome </small> */}
                        <h3 style={{color:'#2D995F'}}>{user}</h3>
                    </div>
                </div>
            </div>
            

        <div className={classes.mainform}>

    <div className={classes.loandgrantcards}>
    {/* <div className={classes.loandethead}>
        <p className={classes.loanText}>Post news on the website from the editor</p>
        <Button variant="light" onClick={addNews}><i class='bx bx-plus-circle'></i> Add New</Button>
    </div> */}
    <div className={classes.loandethead}>
        <div className={classes.formLabel}>
            {/* <h4 style={{color:'#2D995F'}}>Gallery</h4> */}
            {/* <p className={classes.loanText}>Home - Apps - eCommerce - Catalog</p> */}
        </div>
        <div className={classes.formIntBtn}>
            {/* <Button variant="light" className={classes.btn1}> Add Member</Button> */}
            <Button variant="success" onClick={addNew} className={classes.btn2}>Add New</Button>
        </div>
    </div>
    {loading? (
        <small>Fetching documents...</small>
    ) : (
    <div className={classes.loanContainer}>
        <div className={classes.loanResponsive}>
        <Table striped bordered hover>
      <thead>
        <tr>
          <th>S/N</th>
          <th>TITLE</th>
          <th>DESCRIPTION</th>
          <th>DATE POSTED</th>
          <th>AUTHOR</th>
          <th>ACTION</th>
        </tr>
      </thead>
      <tbody>
      {tableData.map((item, index) => (
    <tr key={index}>
      <td>{index + 1}</td>
      <td>{item.name}</td>
      <td>{item.description}</td>
      <td>{new Date(item.created_at).toLocaleDateString()}</td>
      <td>{item.created_by?.name || 'Unknown'}</td>
      <td className={classes.edit}>
        <div style={{ display: 'flex', gap: '10px' }}>
          <i className='bx bx-edit-alt' onClick={() =>handleEditClick(item.id)}></i>
          <i className='bx bx-show-alt' onClick={() =>handleContractCheck(item.id)}></i>
          <i className='bx bxs-trash' style={{ color:'red'}} onClick={()=> handleTrashClick(item.id)}></i>
        </div>
      </td>
    </tr>
  ))}
        
      </tbody>
    </Table>
    <div className={classes.accrodBtns}>
        <Button onClick={handlePrevPage} variant='light' className={classes.prev}>Previous</Button>
            <p style={{ color:'#2D995F'}}>{currentPage} of {totalPages}</p>
        <Button onClick={handleNextPage} variant="success" className={classes.next}>Next</Button>
    </div>
            
        </div>
    </div>
    )}
  </div> 

</div>

        </div>

    </div>
)
}