import React, { useState, useEffect } from 'react';
import classes from './ManageMDAs.module.css';
import RegLogo from '../../Images/RegistrationLogo.svg'
import { Spinner, Badge, Button } from 'react-bootstrap';
import Folder from '../../Images/folder-2.svg';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import ArrowLogo from '../../Images/arrow-left.svg';
import LoanImage from '../../Images/loan bg.svg';
import MainDashoard from '../Main Dashboard/MainDashoard';
import Ready from '../../Images/nothing.svg'
import Ready1 from '../../Images/review.svg';
import { Link, useNavigate } from 'react-router-dom'
import Table from 'react-bootstrap/Table';

export default function ManageMDAs() {
    const navigate = useNavigate();
    // const [bearer, setBearer] = useState('');
    // const [grantDetail, setGrantDetail] = useState([]);
    // const [invoicePayment, setInvoicePayment] = useState([]);
    // const [isLoading, setIsLoading] = useState(false);
    // const [paymentLoading, setPaymentLoading] = useState(false);
    // const [isLoan, setIsLoan] = useState(false);
    // const { isGrant, isHome } = useRegistration();

    const handleEdit = () =>{
        navigate('/edit_media')
    }
    const addNew = () =>{
        navigate('/new_media')
    }


    return (
        <div>
        <MainDashoard />

        <div className={classes.finishedbodyCont}>
            <div className={classes.topPadding}>
                <div className={`${classes.formSecCont}`}>
                    <div className={classes.formSectionHeader}>
                        <h3>Media Editor</h3>
                    </div>
                    <div className={classes.formSectionHeader}>
                        <small>Welcome </small>
                        <h3 style={{color:'#2D995F'}}>user</h3>
                    </div>
                </div>
            </div>
            

        <div className={classes.mainform}>

    <div className={classes.loandgrantcards}>
    {/* <div className={classes.loandethead}>
        <p className={classes.loanText}>Post news on the website from the editor</p>
        <Button variant="light" onClick={addNews}><i class='bx bx-plus-circle'></i> Add New</Button>
    </div> */}
    <div className={classes.loandethead}>
        <div className={classes.formLabel}>
            <h4 style={{color:'#2D995F'}}>Manage MDAs</h4>
            <p className={classes.loanText}>Create and update your mdas...</p>
            {/* <p className={classes.loanText}>Home - Apps - eCommerce - Catalog</p> */}
        </div>
        <div className={classes.formIntBtn}>
            {/* <Button variant="light" className={classes.btn1}> Add Member</Button> */}
            <Button variant="success" onClick={addNew} className={classes.btn2}>Add New user</Button>
        </div>
    </div>

    {/* <div className="d-flex justify-content-between align-items-center" style={{ padding: '20px 0 0 0', marginBottom: 20 }}>
        <div className={classes.greenbtn} style={{ display: 'flex', gap:'20px' }}>
        <div className={classes.actionsBtns}>
            <Button variant='success'>Copy</Button>
            <Button variant='success'>Excel</Button>
            <Button variant='success'>PDF</Button>
            <Button variant='success'>Column visibility</Button>
        </div>
        <div>
            <label className="d-flex justify-content-start align-items-center">
            Show
            <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" className="custom-select custom-select-sm form-control form-control-sm"
            //  value={entriesPerPage}
            //     onChange={(e) => {
            //     setEntriesPerPage(parseInt(e.target.value));
            //     setCurrentPage(1);
            //     }}
                >
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
            </select>
            entries
            </label>
        </div>
        </div>
        <div className="text-right modal-effect ">
        <div id="DataTables_Table_0_filter" className="dataTables_filter">
            <div className="d-flex justify-content-start align-items-center">
            <div className="mr-2">Search:</div>
            <input
                type="search"
                // value={searchTerm}
                className="form-control form-control-sm"
                placeholder=""
                aria-controls="DataTables_Table_0"
                // onChange={(e) => {
                // setSearchTerm(e.target.value);
                
                
                // }}
            />
            </div>

        </div>
        </div>
    </div> */}

    <div className={classes.loanContainer}>
        <div className={classes.loanResponsive}>
        <Table striped bordered hover>
      <thead>
        <tr>
          <th>S/N</th>
          <th>Name</th>
          <th>Email</th>
          <th>Phone Number</th>
          <th>Role</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>1</td>
          <td>Oluwole Suraj Olamilesi</td>
          <td>oluwolesuraj98@gmail.com</td>
          <td>07064782380</td>
          <td>Admin</td>
          <td className={classes.edit} onClick={handleEdit}>Edit</td>
        </tr>
        <tr>
          <td>1</td>
          <td>Oluwole Suraj Olamilesi</td>
          <td>oluwolesuraj98@gmail.com</td>
          <td>07064782380</td>
          <td>Admin</td>
          <td className={classes.edit} onClick={handleEdit}>Edit</td>
        </tr>
        <tr>
          <td>1</td>
          <td>Oluwole Suraj Olamilesi</td>
          <td>oluwolesuraj98@gmail.com</td>
          <td>07064782380</td>
          <td>Admin</td>
          <td className={classes.edit} onClick={handleEdit}>Edit</td>
        </tr>
        <tr>
          <td>1</td>
          <td>Oluwole Suraj Olamilesi</td>
          <td>oluwolesuraj98@gmail.com</td>
          <td>07064782380</td>
          <td>Admin</td>
          <td className={classes.edit} onClick={handleEdit}>Edit</td>
        </tr>
        <tr>
          <td>1</td>
          <td>Oluwole Suraj Olamilesi</td>
          <td>oluwolesuraj98@gmail.com</td>
          <td>07064782380</td>
          <td>Admin</td>
          <td className={classes.edit} onClick={handleEdit}>Edit</td>
        </tr>
        <tr>
          <td>1</td>
          <td>Oluwole Suraj Olamilesi</td>
          <td>oluwolesuraj98@gmail.com</td>
          <td>07064782380</td>
          <td>Admin</td>
          <td className={classes.edit} onClick={handleEdit}>Edit</td>
        </tr>
        
        
      </tbody>
    </Table>
    <div className={classes.accrodBtns}>
        <Button variant='light' className={classes.prev}>Previous</Button>
            <p style={{ color:'#2D995F'}}>1 of 5</p>
        <Button variant="success" className={classes.next}>Next</Button>
    </div>
            
        </div>
    </div>
</div> 

</div>

        </div>

    </div>
)
}