import React, { useState, useEffect, useRef } from 'react';
import classes from './EditMedia.module.css';
import RegLogo from '../../Images/RegistrationLogo.svg'
import { Spinner, Badge, Button } from 'react-bootstrap';
import Folder from '../../Images/folder-2.svg';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import ArrowLogo from '../../Images/arrow-left.svg';
import LoanImage from '../../Images/loan bg.svg';
import MainDashoard from '../Main Dashboard/MainDashoard';
import Ready from '../../Images/nothing.svg'
import Ready1 from '../../Images/review.svg';
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Table from 'react-bootstrap/Table';
import placeHolder from "../../assets/imagePlaceHolder.jpg"
// import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import InputGroup from 'react-bootstrap/InputGroup';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // import styles
import './CustomQuillToolbar.css';
import { BASE_URL } from '../Api/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function EditMedia() {
    const navigate = useNavigate();
    const location = useLocation();
    const { selectedMedia } = location.state || {};
    const [editorHtml, setEditorHtml] = useState('');
    const [inputText, setInputText] = useState('');
    const [imageSrc, setImageSrc] = useState([]);
    const fileInputRef = useRef(null);
    const [bearer, setBearer] = useState('');
    const [user, setUser] = useState('');
    const [loading, setLoading] = useState(false);
    const [banner, setBanner] = useState('');
    const [selectedImage, setSelectedImage] = useState(null);
    const [newsTitle, setNewsTitle] = useState(selectedMedia?.title || '');
    const [shortDescription, setShortDescription] = useState(selectedMedia?.description || '');
    const [longDescription, setLongDescription] = useState('');
    const [newsCategory, setNewsCategory] = useState('');
    const [newsType, setNewsType] = useState(selectedMedia?.type || '');
    const [fileLink, setFileLink] = useState(selectedMedia?.file_path || '');
    const [uploadLoading, setUploadLoading] = useState(false);

    const readData = async () => {
        try {
          const details = await AsyncStorage.getItem('userToken');
          const users = await AsyncStorage.getItem('fullName');
                 
          if (details !== null) {
            setBearer(details);
          }
          if (users !== null) {
            setUser(users);
          }
    
        } catch (e) {
          alert('Failed to fetch the input from storage');
        }
      };
    
      useEffect(() => {
        readData();
      }, []);
    


    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${bearer}`
      };

      const uploadVideo = async () => {
        setUploadLoading(true);
        try {
          const response = await axios.post(
            `${BASE_URL}/media/update`,
            {
            title: newsTitle,
            description: shortDescription,
            file_path: fileLink,
            type: newsType,
            id: selectedMedia.id
             
            },
            { headers }
          );
          console.log(response)
          setNewsTitle('');
          setNewsType('');
          setShortDescription('');
          setFileLink('');
        navigate('/media')
         toast.success(response.data.message);
          console.log(response.data);
    
        } catch (error) {
            let errorMessage = 'An error occurred. Please try again.';
            if (error.response && error.response.data && error.response.data.message) {
                if (typeof error.response.data.message === 'string') {
                    errorMessage = error.response.data.message;
                } else if (Array.isArray(error.response.data.message)) {
                    errorMessage = error.response.data.message.join('; ');
                } else if (typeof error.response.data.message === 'object') {
                    errorMessage = JSON.stringify(error.response.data.message);
                }
            }
            toast.error(errorMessage);
          setUploadLoading(false);
        }
      };
      

      const handleTitleChange = (event) => {
        setNewsTitle(event.target.value);
      };
      const handleBannerChange = (event) => {
        setBanner(event.target.value);
      };
      const handleShortDescriptionChange = (event) => {
        setShortDescription(event.target.value);
      };
      const handleLongDescriptionChange = (event) => {
        setLongDescription(event.target.value);
      };
      const handleCategoryChange = (event) => {
        setNewsCategory(event.target.value);
      };
    //   const handleBannerChange = (event) => {
    //     setBanner(event.target.value);
    //   };

    const handleEditorChange = (content, delta, source, editor) => {
    setEditorHtml(content);
  };

  const handleInputChange = (event) => {
    setInputText(event.target.value);
  };
    
  const applyInputText = () => {
    // You can add logic to format the text input as needed
    setEditorHtml(editorHtml + inputText);
    setInputText('');
  };

  const modules = {
    toolbar: [
    //   [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
      [{size: []}],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    //   [{'list': 'ordered'}, {'list': 'bullet'}, 
    //    {'indent': '-1'}, {'indent': '+1'}],
      [ 'image'],
    //   ['clean']                                        
    ],
  };

    const handleGodBack = () =>{
        navigate(-1);
    }
    const openNews = () =>{
        navigate('/subnew')
    }

    

    //   const handleImageChange = (event) => {
    //     const file = event.target.files[0];
    //     if (file && (file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'image/jpg')) {
    //       const reader = new FileReader();
    //       reader.onloadend = () => {
    //         setImageSrc(reader.result);
    //       };
    //       reader.readAsDataURL(file);
    //     } else {
    //       alert('Only *.png, *.jpg and *.jpeg image files are accepted.');
    //       setImageSrc(placeHolder)
    //     }
    //   };
//     const handleImageChange = (event) => {
//         const files = event.target.files;
//         imageSrc(Array.from(files));
//       };
        const handleImageChange = (event) => {
            const files = event.target.files;
            setImageSrc(Array.from(files));
            setSelectedImage(URL.createObjectURL(files[0]));
        };

        const handleTypeChange = (event) => {
          setNewsType(event.target.value);
        };

        const handleLinkChange = (event) => {
          setFileLink(event.target.value);
        };
      
    

    return (
        <div>
        <MainDashoard />

        <div className={classes.finishedbodyCont}>
            <div className={classes.topPadding}>
                <div className={`${classes.formSecCont}`}>
                    <div className={classes.formSectionHeader}>
                        <h3>Update Video</h3>
                    </div>
                    <div className={classes.formSectionHeader}>
                        {/* <small>Welcome </small> */}
                        <h3 style={{color:'#2D995F'}}>user</h3>
                    </div>
                </div>
            </div>
            
        <div className={classes.mainform}>

    <div className={classes.loandgrantcards}>
        <div className={classes.loandethead}>
            {/* <div className={classes.formLabel}>
                <h4>Add New</h4>
                <p className={classes.loanText}>Fill in the details below to add new news...</p>
            </div> */}
            {/* <div className={classes.formIntBtn}>
                <Button variant="light" className={classes.btn1}> Add Member</Button>
                <Button variant="success" className={classes.btn2}>New Campaign</Button>
            </div> */}
        </div>

        <div className={classes.loanContainer}>
            <div className={classes.loanResponsive}>
                
                <div className={classes.mainForm}>
                    {/* <h5>General</h5> */}
                    <Form className={classes.form}>

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Title<span className={classes.span}>*</span></Form.Label>
                            <Form.Control type="text" placeholder="Add Title"
                            value={newsTitle}
                            onChange={handleTitleChange}
                            />
                        </Form.Group>

                        
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Type<span className={classes.span}>*</span></Form.Label>
                            <Form.Select name="customer" className="form-control" required="" 
                           value={newsType}
                           onChange={handleTypeChange}
                           >
                           <option value="1">General</option>
                           <option value="2">Department</option>
                                        
                         </Form.Select>
                            
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Description<span className={classes.span}>*</span></Form.Label>
                            <Form.Control type="text" placeholder="Enter Description"
                            value={shortDescription}
                            onChange={handleShortDescriptionChange}/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Enter File Link<span className={classes.span}>*</span></Form.Label>
                            <Form.Control type="text" placeholder="Enter Link"
                            value={fileLink}
                            onChange={handleLinkChange}/>
                        </Form.Group>

                        {/* <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Long Description<span className={classes.span}>*</span></Form.Label>
                            <Form.Control type="text" placeholder="News Status"/>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Category<span className={classes.span}>*</span></Form.Label>
                            <Form.Control type="text" placeholder="Registration Name"/>
                        </Form.Group> */}

                        
                    </Form>

                    <div>
                        {/* <ReactQuill 
                            value={editorHtml}
                            onChange={handleEditorChange}
                            modules={modules}
                        /> */}
                        {/* <input 
                            type="text"
                            value={inputText}
                            onChange={handleInputChange}
                            placeholder="Enter text to format"
                        /> */}
                        {/* <button onClick={applyInputText}>Apply Text</button> */}
                    </div>
                </div>
               
            </div>
            <div className={`${classes.formIntBtn} ${classes.formIntBtn2}`}>
                <Button variant="light" className={classes.btn1} onClick={handleGodBack}> Cancel</Button>
                <Button variant="success" className={classes.btn2} onClick={uploadVideo}>
                    {uploadLoading ? (
                        <>
                            <Spinner size='sm' />
                            <span style={{ marginLeft: '5px' }}>Updating, Please wait...</span>
                        </>
                    ) : (
                        "Update Video"
                    )}
                </Button>
            </div>
            {/* <div className={classes.footerCont}>
                <div>
                    <small>2024© <Link to={'#'}> Ogun State</Link></small>
                </div>
                <div>
                    <small className={classes.small}>
                        <Link to={'#'}>About</Link>
                        <Link to={'#'}>Support</Link>
                        <Link to={'#'}>Purchase</Link>
                    </small>
                </div>
            </div> */}
        </div>
    </div> 

</div>

        </div>

    </div>
)
}