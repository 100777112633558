import React, { useState, useEffect, useRef } from 'react';
import classes from './NewMedia.module.css';
import RegLogo from '../../Images/RegistrationLogo.svg'
import { Spinner, Badge, Button } from 'react-bootstrap';
import Folder from '../../Images/folder-2.svg';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import ArrowLogo from '../../Images/arrow-left.svg';
import LoanImage from '../../Images/loan bg.svg';
import MainDashoard from '../Main Dashboard/MainDashoard';
import Ready from '../../Images/nothing.svg'
import Ready1 from '../../Images/review.svg';
import { Link, useNavigate } from 'react-router-dom'
import Table from 'react-bootstrap/Table';
import placeHolder from "../../assets/imagePlaceHolder.jpg"
// import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import InputGroup from 'react-bootstrap/InputGroup';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // import styles
// import './CustomQuillToolbar.css';
import { BASE_URL } from '../Api/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function CreateContract() {
    const navigate = useNavigate();
    const [editorHtml, setEditorHtml] = useState('');
    const [inputText, setInputText] = useState('');
    const [imageSrc, setImageSrc] = useState([]);
    const fileInputRef = useRef(null);
    const [bearer, setBearer] = useState('');
    const [user, setUser] = useState('');
    const [loading, setLoading] = useState(false);
    const [banner, setBanner] = useState('');
    const [selectedImage, setSelectedImage] = useState(null);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [document, setDocument] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [totalEntries, setTotalEntries] = useState("");
    const [tableData2, setTableData2] = useState([]);
    const [tableData1, setTableData1] = useState([]);
    const [selectedMDA, setSelectedMDA] = useState("");
    
    const readData = async () => {
        try {
          const details = await AsyncStorage.getItem('userToken');
          const users = await AsyncStorage.getItem('fullName');
                 
          if (details !== null) {
            setBearer(details);
          }
          if (users !== null) {
            setUser(users);
          }
    
        } catch (e) {
          alert('Failed to fetch the input from storage');
        }
      };
    
      useEffect(() => {
        readData();
      }, []);
    


    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${bearer}`
      };


      const fetchAllMDAs = async () => {
        setIsLoading(true);
        let allData = [];
        let currentPage = 1;
        let totalPages = 1;
    
        try {
          do {
            const response = await axios.get(`${BASE_URL}/get-all-mda?page=${currentPage}`, {
              headers: { 'Authorization': `Bearer ${bearer}` } // Include other headers if needed
            });
    
            const results = response.data?.data?.data || [];
            const total = response.data?.data?.last_page || 1;
            const resultx = response.data?.data?.total;
    
            allData = [...allData, ...results];
            totalPages = total;
            currentPage += 1;
    
            setTotalEntries(resultx); // You might want to set this only once
          } while (currentPage <= totalPages);
    
          setTableData2(allData); // Store the entire objects
        } catch (error) {
          if (error.response && error.response.status === 401) {
            // Redirect to login page if unauthorized
            navigate('/login');
          } else {
            const errorStatus = error.response?.data?.message;
            console.error(errorStatus);
            // setTableData1([]);
          }
        } finally {
          setIsLoading(false);
        }
      };
    
      useEffect(() => {
        if (bearer) {
          fetchAllMDAs();
        }
      }, [bearer]);

      console.log(selectedMDA, title, document, "selected MDA")
    const createAdvart= async () => {
        setLoading(true);
        
        try {
          const formData = new FormData();
          formData.append('name', title);
          formData.append('description', description);
          formData.append('mda', selectedMDA);
        //   formData.append('document', document);
        document.forEach((file) => {
            formData.append('file', file);
        });
          console.log(title, description, document, "all here")
            const response = await axios.post(`${BASE_URL}/create-mda-archive`,
            formData,
            {
              headers: {
                ...headers,
                'Content-Type': 'multipart/form-data',
              },
            }
          );
            console.log( "All details here", title, description, document, description )
              console.log(response.data.message)
                setTitle("");
                setDocument("");
                setDescription("");
                // setEditorHtml("");
                // setNewsCategory("");
                navigate('/contract');

                    toast.success(response.data.message);
                      console.log(response.data);
                  } catch (error) {
                      let errorMessage = 'An error occurred. Please try again.';
                      if (error.response && error.response.data && error.response.data.message) {
                          if (typeof error.response.data.message === 'string') {
                              errorMessage = error.response.data.message;
                          } else if (Array.isArray(error.response.data.message)) {
                              errorMessage = error.response.data.message.join('; ');
                          } else if (typeof error.response.data.message === 'object') {
                              errorMessage = JSON.stringify(error.response.data.message);
                          }
                      }
                      toast.error(errorMessage);
                      console.log(errorMessage);
                  } finally {
                      setLoading(false);
                  }
    };
      

      const handleTitleChange = (event) => {
        setTitle(event.target.value);
      };
      const handleMdaChange = (event) => {
        setSelectedMDA(event.target.value);
      };
    
    //   const handleDocumentChange= (event) => {
    //     const files = event.target.files;
    //     setDocument(Array.from(files));
    //     // setSelectedImage(URL.createObjectURL(files[0]));
    // };

    const handleDocumentChange = (event) => {
        const files = event.target.files;
        setDocument(Array.from(files));
        // setSelectedImage(URL.createObjectURL(files[0]));
    };


      const handleDescriptionChange = (event) => {
        setDescription(event.target.value);
      };
    

    const handleGodBack = () =>{
        navigate(-1);
    }
    // const openNews = () =>{
    //     navigate('/subnew')
    // }


    

    return (
        <div>
        <MainDashoard />

        <div className={classes.finishedbodyCont}>
            <div className={classes.topPadding}>
                <div className={`${classes.formSecCont}`}>
                    <div className={classes.formSectionHeader}>
                        <h3>Upload Documents</h3>
                    </div>
                    <div className={classes.formSectionHeader}>
                        {/* <small>Welcome </small> */}
                        <h3 style={{color:'#2D995F'}}>{user}</h3>
                    </div>
                </div>
            </div>
            
        <div className={classes.mainform}>

    <div className={classes.loandgrantcards}>
        <div className={classes.loandethead}>
            {/* <div className={classes.formLabel}>
                <h4>Add New</h4>
                <p className={classes.loanText}>Fill in the details below to add new news...</p>
            </div> */}
            {/* <div className={classes.formIntBtn}>
                <Button variant="light" className={classes.btn1}> Add Member</Button>
                <Button variant="success" className={classes.btn2}>New Campaign</Button>
            </div> */}
        </div>

        <div className={classes.loanContainer}>
            <div className={classes.loanResponsive}>
                
                <div className={classes.mainForm}>
                    {/* <h5>General</h5> */}
                    <Form className={classes.form}>

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Name<span className={classes.span}>*</span></Form.Label>
                            <Form.Control type="text" placeholder="Add Title"
                            value={title}
                            onChange={handleTitleChange}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Description<span className={classes.span}>*</span></Form.Label>
                            <Form.Control type="text" placeholder="Description"
                                value={description}
                                onChange={handleDescriptionChange}
                            />
                        </Form.Group>
                        
                        {/* <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Type<span className={classes.span}>*</span></Form.Label>
                            <Form.Select name="customer" className="form-control" required="" 
                            value={newsCategory}
                            onChange={handleCategoryChange}
                            >
                            <option>General</option>
                            <option>Department</option>
                                        
                         </Form.Select>
                            
                        </Form.Group> */}

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Select MDA<span className={classes.span}>*</span></Form.Label>
                            {/* <Form.Control type="text" placeholder="Enter Description"
                            value={description}
                            onChange={handleDescriptionChange}/> */}
                             
                            <Form.Select name="customer" className="form-control" required="" 
                            value={selectedMDA}
                            onChange={handleMdaChange}
                            >
                            <option>Select mda</option>
                            {tableData2.map((item)=>(
                            <option key={item.id} value={item.id}>
                                {item.name}
                            </option>
                        ))}
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Upload Document<span className={classes.span}>*</span></Form.Label>
                            <Form.Control 
                            required
                            type="file"
                            accept=".pdf"
                            onChange={handleDocumentChange}
                            name="file"
                            />
                            
                        </Form.Group>

                        {/* <div className={classes.banner}>
                    <h5 style={{fontSize:'14px'}}>Upload Document</h5>
                    <div className={classes.imgSec}>
                        <div className={classes.imgCont}>
                            <div className={classes.mainImgCont}>
                            <>
                                {selectedImage && (
                                    <div>
                                        <p>Selected PDF: {selectedImage.name}</p>
                                    </div>
                                )}
                            </>
                            </div>
                        </div>
                        <p>Set the product thumbnail. Only *.pdf files are accepted</p>
                        <div className={classes.imgInput}>
                        <input
                            required=""
                            type="file"
                            accept=".pdf"
                            onChange={handleDocumentChange}
                            name="file"
                        />
                        </div>
                    </div>
                </div>     */}
                        {/* <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Enter File Link<span className={classes.span}>*</span></Form.Label>
                            <Form.Control type="text" placeholder="Enter Link"
                            value={shortDescription}
                            onChange={handleShortDescriptionChange}/>
                        </Form.Group> */}

                        {/* <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Long Description<span className={classes.span}>*</span></Form.Label>
                            <Form.Control type="text" placeholder="News Status"/>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Category<span className={classes.span}>*</span></Form.Label>
                            <Form.Control type="text" placeholder="Registration Name"/>
                        </Form.Group> */}

                        
                    </Form>

                    <div>
                        {/* <ReactQuill 
                            value={editorHtml}
                            onChange={handleEditorChange}
                            modules={modules}
                        /> */}
                        {/* <input 
                            type="text"
                            value={inputText}
                            onChange={handleInputChange}
                            placeholder="Enter text to format"
                        /> */}
                        {/* <button onClick={applyInputText}>Apply Text</button> */}
                    </div>
                </div>
               
            </div>
            <div className={`${classes.formIntBtn} ${classes.formIntBtn2}`}>
                <Button variant="light" className={classes.btn1} onClick={handleGodBack}> Cancel</Button>
                <Button variant="success" className={classes.btn2} onClick={createAdvart}>
                    {loading ? (
                        <>
                            <Spinner size='sm' />
                            <span style={{ marginLeft: '5px' }}>Uploading, Please wait...</span>
                        </>
                    ) : (
                        "Upload Document"
                    )}
                </Button>
            </div>
            {/* <div className={classes.footerCont}>
                <div>
                    <small>2024© <Link to={'#'}> Ogun State</Link></small>
                </div>
                <div>
                    <small className={classes.small}>
                        <Link to={'#'}>About</Link>
                        <Link to={'#'}>Support</Link>
                        <Link to={'#'}>Purchase</Link>
                    </small>
                </div>
            </div> */}
        </div>
    </div> 

</div>

        </div>

    </div>
)
}