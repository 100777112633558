import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import OnbImg from '../../Images/image bg.png';
import classes from './Dashboard.module.css';
import loanicon from '../../Images/moneys.png'
import loaniconblue from '../../Images/moneysblue.png'
import loanicongreen from '../../Images/receipt-2.png'
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Button, Spinner } from 'react-bootstrap';
import RegLogo from '../../Images/RegistrationLogo.svg'
import { Link } from 'react-router-dom'
import MainDashoard from '../Main Dashboard/MainDashoard';
import icon01 from '../../assets/icon01.png';
import icon02 from '../../assets/icon02.png';
import icon03 from '../../assets/icon03.png';
import icon04 from '../../assets/icon04.png';
import verfIcon from '../../assets/verfIcon.png';
import fall from '../../assets/fall.png';
import rise from '../../assets/rise.png';
import { BASE_URL } from '../Api/Api';

const Dashboard = () => {
  const [bearer, setBearer] = useState('');
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [regType, setRegType] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [accountName, setAccountName] = useState('');
  const [accountBVN, setAccountBVN] = useState('');
  const [businessAddress, setBusinessAddress] = useState('');
  const [businessName, setBusinessName] = useState('');
  const [businessPhone, setBusinessPhone] = useState('');
  const [businessEmail, setBusinessEmail] = useState('');
  const [businessTax, setBusinessTax] = useState('');
  const [businessEmployess, setBusinessEmployess] = useState('');
  const [businessRc, setBusinessRc] = useState('');
  const [lastName, setLastName] = useState('');
  const [nin, setNin] = useState('');
  const [nokName, setNokName] = useState('');
  const [nokPhone, setNokPhone] = useState('');
  const [nokAddress, setNokAddress] = useState('');
  const [nokEmail, setNokEmail] = useState('');
  const [otherName, setOtherName] = useState('');
  const [businessPermit, setBusinessPermit] = useState('');
  const [cacNo, setCacNo] = useState('');
  const [turnover, setTurnover] = useState('');
  const [dateOfBirth, setDateofBirth] = useState('');
  const [homeAddress, setHomeAddress] = useState('');
  const [permanentAddress, setPermanentAddress] = useState('');
  const [selectedBank, setSelectedBank] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [selectedLocalGovt, setSelectedLocalGovt] = useState('');
  const [selectedNokRelationship, setSelectedNokRelationship] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedSector, setSelectedSector] = useState('');
  const [user, setUser] = useState('');
  const [mda, setMda] = useState('');
  const [isReg, setIsReg] = useState(false);
  const [userProfile, setUserProfile] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);

  const navigate = useNavigate();

  const handleCreateNews = () => {
    navigate('/news_editor');
  }

  const handlePhoto = () => {
    navigate('/gallery');
  }
  const handleVideo = () => {
    navigate('/media');
  }

  const handleContracts = () => {
    navigate('/contract');
  }


  useEffect(() => {
    const retrieveRegStatus = async () => {
      try {
        const regStatus = await AsyncStorage.getItem('isComplete');
        setIsReg(regStatus === 'true');



      } catch (error) {
        console.error('Error retrieving admin status:', error);
      }
    };

    retrieveRegStatus();
  }, []);

  const readData = async () => {
    try {
      const detail = await AsyncStorage.getItem('fullName');
      const details = await AsyncStorage.getItem('userToken');
      const detailss = await AsyncStorage.getItem('mda');


      if (detail !== null) {
        setUser(detail);
      }


      if (details !== null) {
        setBearer(details);
      }
      if (detailss !== null) {
        setMda(detailss);
      }
    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  useEffect(() => {
    readData();
  }, []);

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };

  console.log(mda, "hello");

  const fetchCount = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/count`, { headers });
      const results = response.data?.data;
      
      setTableData(results);
      console.log(results);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);
        setTableData([]);
      }
    } finally {
      setIsLoading(false);
    }
  };



  useEffect(() => {
    if (bearer) {
      fetchCount();
    }
  }, [bearer]);


  return (
    <div>
      <MainDashoard />
      <div className={classes.finishedbodyCont}>
        <div className={classes.topSpace}>
          <div className={`${classes.formSecCont}`}>
            <div className={classes.welcome}>
              <h3>Welcome, </h3>
              <h3 style={{ color: "#2D995F" }}> {user}</h3>
            </div>

            {/* <div className={classes.formSectionHeader}>
                            <small>Welcome </small>
                            <h3 style={{color:'#2D995F'}}>user</h3>
                        </div> */}
            <div>
              <h3 style={{ color: "#2D995F" }}> {mda}</h3>
            </div>
          </div>
        </div>
        <div className={`${classes.mainform} ${classes.shadow}`}>
          <div className={classes.headerCards}>
            <div className={`${classes.card} ${classes.headerCard1}`}>
              <p className={classes.headerPaymentTxt}>Total Number of Users</p>
              <div className={classes.figAmtCon}>
                {isLoading ? <Spinner size="small"/> : Number(tableData?.total_User)}
              </div>
            </div>
            <div className={`${classes.card} ${classes.headerCard1}`}>
              <p className={classes.headerPaymentTxt}>Total Number of News Created</p>
              <div className={classes.figAmtCon}>
              {isLoading ? <Spinner size="small"/> : Number(tableData?.total_News)}
              </div>
            </div>
            <div className={`${classes.card} ${classes.headerCard1}`}>
              <p className={classes.headerPaymentTxt}>MDA Users</p>
              <div className={classes.figAmtCon}>
              {isLoading ? <Spinner size="small"/> : Number(tableData?.total_mda_user)}
              </div>
            </div>
            {/* <div className={`${classes.card} ${classes.headerCard2}`}>
              <div>
                <p className={classes.sumitTxt}>Submit your Form to the Admin Office</p>
                <div className={classes.undrsub}>Please update/verify your information before<br /><strong>13th July 2023 </strong> to unlock level benefits</div>
                <p className={classes.cmpVer}>Complete Verfication</p>
              </div>
              <img src={verfIcon} className={classes.verfIcon} />
            </div> */}
          </div>
          <div>
            <div className={classes.loandgrantcards}>
              <div className={classes.leftGrid}>
                <h5>Quick Actions</h5>
                <div className={classes.loandcards}>
                  <div className={`${classes.card} ${classes.card1}`} onClick={handleCreateNews}>
                    <img src={icon01} alt="The marketers" className={classes.icon01} />
                    <p className={classes.applyText}>Create & Update News</p>
                    <p className={classes.about}>Publish news effortlessly <br /> to highlight the latest trends and events.</p>
                    {/* <p className={classes.applyText}></p> */}
                    {/* <p className={classes.pdisc}>Get up to N100,000 for your
                                            Business</p> */}
                  </div>
                  <div className={`${classes.card} ${classes.card2}`} onClick={handlePhoto}>
                    <img src={icon02} alt="The marketers" className={classes.icon02} />
                    <p className={classes.applyText}>Update Photo Gallery</p>
                    <p className={classes.about}>Effortlessly update the gallery
                      to showcase the latest trends and events.</p>
                    {/* <p className={classes.applyText}></p> */}
                    {/* <p className={classes.pdisc}>Get up to N100,000 for your
                                            Business</p> */}
                  </div>

                  <div className={`${classes.card} ${classes.card3}`} onClick={handleContracts}>
                    <img src={icon03} alt="The marketers" className={classes.icon03} />
                    <p className={classes.applyText}>Upload Documents</p>
                    <p className={classes.about}>To promote transparency and keep the public informed about government activities. </p>
                    {/* <p className={classes.applyText}></p> */}
                    {/* <p className={classes.pdisc}>Get up to N100,000 for your
                                            Business</p> */}
                  </div>

                  {/* <div className={classes.loancardgreen}> */}
                  <div className={`${classes.card} ${classes.card4}`} onClick={handleVideo}>
                    <img src={icon04} alt="The marketers" className={classes.icon04} />
                    <p className={classes.applyText}>Update Video Gallery</p>
                    <p className={classes.about}>Effortlessly update the gallery
                      to showcase the latest trends and events.</p>
                    {/* <p className={classes.applyText}>Payment</p>
                                        <p className={classes.pdisc}>You have a pending payment of
                                            N50,000 to be made before the
                                            12th of May, 2024</p> */}
                  </div>
                  {/* <div className={classes.loancardgreen}> */}
                  {/* <div className={`${classes.card} ${classes.card5}`}>
                    <div>

                      <p className={classes.sumitTxt}>Submit your Form to the Admin Office</p>

                      <div className={classes.undrsub}>Please update/verify your information before<br /><strong>13th July 2023 </strong>to unlock level benefits</div>
                      <p className={classes.cmpVer}>Complete your Registration</p>

                    </div>
                    <img src={verfIcon} className={classes.verfIcon} />
                  </div> */}

                </div>
              </div>
              <div className={classes.RightContents}>


              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}

export default Dashboard;