import React, { useState, useEffect } from 'react';
import classes from './ManageRole.module.css';
import RegLogo from '../../Images/RegistrationLogo.svg'
import { Spinner, Badge, Button } from 'react-bootstrap';
import Folder from '../../Images/folder-2.svg';
import axios from 'axios';
import ArrowLogo from '../../Images/arrow-left.svg';
import LoanImage from '../../Images/loan bg.svg';
import MainDashoard from '../Main Dashboard/MainDashoard';
import Ready from '../../Images/nothing.svg'
import Ready1 from '../../Images/review.svg';
import { Link, useNavigate } from 'react-router-dom'
import Table from 'react-bootstrap/Table';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { BASE_URL } from '../Api/Api';
import { toast, ToastContainer } from 'react-toastify';
import Swal from 'sweetalert2';

export default function ManageRole() {
    const navigate = useNavigate();
    const [bearer, setBearer] = useState('');
    const [user, setUser] = useState('');
    const [roleLoading, setRoleLoading] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [perm, setPerm] = useState([]);
    const [toggleStates1, setToggleStates1] = useState({});
    const [selectedRoleId, setSelectedRoleId] = useState(null);
    const [permissions, setPermissions] = useState([]);

    const handleEdit = () => {
        navigate('/edit_media')
    }
    const addNew = () => {
        navigate('/create_role')
    }

    const readData = async () => {
        try {
            const detail = await AsyncStorage.getItem('fullName');
            const details = await AsyncStorage.getItem('userToken');


            if (detail !== null) {
                setUser(detail);
            }


            if (details !== null) {
                setBearer(details);
            }
        } catch (e) {
            alert('Failed to fetch the input from storage');
        }
    };

    useEffect(() => {
        readData();
    }, []);

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${bearer}`
    };



    const fetchRole = async () => {
        setRoleLoading(true);
        try {
            const response = await axios.get(`${BASE_URL}/role/get-roles`, { headers });
            const results = response.data?.data;
            console.log(results);
            setTableData(results);
        } catch (error) {
            let errorMessage = 'An error occurred. Please try again.';
            if (error.response && error.response.data && error.response.data.message) {
                if (typeof error.response.data.message === 'string') {
                    errorMessage = error.response.data.message;
                } else if (Array.isArray(error.response.data.message)) {
                    errorMessage = error.response.data.message.join('; ');
                } else if (typeof error.response.data.message === 'object') {
                    errorMessage = JSON.stringify(error.response.data.message);
                }
            }
            toast.error(errorMessage);
        } finally {
            setRoleLoading(false);
        }
    };



    useEffect(() => {
        if (bearer) {
            fetchRole();
        }
    }, [bearer]);

    function formatDate(dateString) {
        const date = new Date(dateString);
        const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())} ${padZero(date.getHours())}:${padZero(date.getMinutes())} ${date.getHours() >= 12 ? 'PM' : 'AM'}`;
        return formattedDate;
    }

    function padZero(num) {
        return num < 10 ? `0${num}` : num;
    }


  
    const handleEyeClick = async (roleId) => {

        try {
            const response = await axios.get(`${BASE_URL}/role?role_id=${roleId}`, { headers });
            const roless = response.data?.data;

            const permissionRoles = roless.permissions.map(item => item.id);
            //   console.log(permissionRoles, "permission roles");
            setPerm(permissionRoles);
            navigate('/view_role', { state: { selectedPermission: permissionRoles, selectedRoles: roless } });

            const selectedRole = tableData.find((role) => role.id === roleId);

            if (selectedRole) {
                // Set the selected role's permissions as true in toggleStates1
                const updatedToggleStates1 = Object.fromEntries(
                    permissions.map((permission) => [
                        permission.id,
                        permissionRoles.includes(permission.id),
                    ])
                );

                setToggleStates1(updatedToggleStates1);

                setSelectedRoleId(roleId);
                // setTrashClicked(true);
            }
        } catch (error) {
            const errorStatus = error.response?.data?.message;
            console.log(errorStatus);
        }
    };

    const handleTrashClick = async (id) => {
        try {
            const confirmed = await Swal.fire({
                title: 'Are you sure?',
                text: 'You are about to delete this role.',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, cancel',
              });

          if (!confirmed.isConfirmed) {
            return; 
          }

          const response = await axios.get(`${BASE_URL}/role/delete-role?role_id=${id}`, { headers });
          fetchRole();
          toast.success(response.data.message);
        } catch (error) {
          let errorMessage = 'An error occurred. Please try again.';

          if (error.response?.data?.message) {
            if (typeof error.response.data.message === 'string') {
              errorMessage = error.response.data.message;
            } else if (Array.isArray(error.response.data.message)) {
              errorMessage = error.response.data.message.join('; ');
            } else if (typeof error.response.data.message === 'object') {
              errorMessage = JSON.stringify(error.response.data.message);
            }
          }

          toast.error(errorMessage);
        }
      };
    


    return (
        <div>
            <ToastContainer />
            <MainDashoard />

            <div className={classes.finishedbodyCont}>
                <div className={classes.topPadding}>
                    <div className={`${classes.formSecCont}`}>
                        <div className={classes.formSectionHeader}>
                            <h3>Manage Roles</h3>
                        </div>
                        <div className={classes.formSectionHeader}>
                            <h3 style={{ color: '#2D995F' }}>{user}</h3>
                        </div>
                    </div>
                </div>


                <div className={classes.mainform}>

                    <div className={classes.loandgrantcards}>
                        <div className={classes.loandethead}>
                            <div className={classes.formLabel}>
                            </div>
                            <div className={classes.formIntBtn}>
                                <Button variant="success" onClick={addNew} className={classes.btn2}>Add New role</Button>
                            </div>
                        </div>

                        <div className="d-flex justify-content-between align-items-center" style={{ padding: '20px 0 0 0', marginBottom: 20 }}>
                            <div className={classes.greenbtn} style={{ display: 'flex', gap: '20px' }}>
                                <div className={classes.actionsBtns}>
                                    <Button variant='success'>Copy</Button>
                                    <Button variant='success'>Excel</Button>
                                    <Button variant='success'>PDF</Button>
                                    <Button variant='success'>Column visibility</Button>
                                </div>
                                <div>
                                    <label className="d-flex justify-content-start align-items-center">
                                        Show
                                        <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" className="custom-select custom-select-sm form-control form-control-sm"
                                        >
                                            <option value={10}>10</option>
                                            <option value={25}>25</option>
                                            <option value={50}>50</option>
                                            <option value={100}>100</option>
                                        </select>
                                        entries
                                    </label>
                                </div>
                            </div>
                            <div className="text-right modal-effect ">
                                <div id="DataTables_Table_0_filter" className="dataTables_filter">
                                    <div className="d-flex justify-content-start align-items-center">
                                        <div className="mr-2">Search:</div>
                                        <input
                                            type="search"
                                            className="form-control form-control-sm"
                                            placeholder=""
                                            aria-controls="DataTables_Table_0"
                                        />
                                    </div>

                                </div>
                            </div>
                        </div>

                        {roleLoading ? (
                            <p className={classes.fetchedTxt}>Fetching Roles...</p>
                        ) : (
                            <div className={classes.loanContainer}>
                                <div className={classes.loanResponsive}>
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>S/N</th>
                                                <th>Role Name</th>
                                                <th>Date Created</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {tableData.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{item.name}</td>
                                                    <td>{formatDate(item.created_at)}</td>
                                                    <td className={classes.edit}>
                                                    <div style={{display:'flex', gap:'10px'}}>
                                                            <i class='bx bx-edit-alt' onClick={() => handleEyeClick(item.id)}></i>
                                                            <i class='bx bxs-trash' onClick={() => handleTrashClick(item.id)}  style={{color:'red'}}></i>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                    {/* <div className={classes.accrodBtns}>
                                        <Button variant='light' className={classes.prev}>Previous</Button>
                                        <p style={{ color: '#2D995F' }}>1 of 5</p>
                                        <Button variant="success" className={classes.next}>Next</Button>
                                    </div> */}


                                </div>
                            </div>
                        )}
                    </div>

                </div>

            </div>

        </div>
    )
}