import React, { useState, useEffect, useRef } from 'react';
import classes from './EditDepartmentalNews.module.css';
import { Spinner, Button } from 'react-bootstrap';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useNavigate, useLocation } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import placeHolder from "../../assets/imagePlaceHolder.jpg";
import { BASE_URL } from '../Api/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import MainDashboard from '../Main Dashboard/MainDashboard';
import MainDashoard from '../Main Dashboard/MainDashoard';

export default function EditDepartmentalNews() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [bearer, setBearer] = useState("");
    const [user, setUser] = useState("");
    const location = useLocation();
    const { selectedNews } = location.state || {};

    const [newsTitle, setNewsTitle] = useState(selectedNews?.title || '');
    const [shortDescription, setShortDescription] = useState(selectedNews?.short_desc || '');
    const [longDescription, setLongDescription] = useState(selectedNews?.long_desc || '');
    const [newsCategory, setNewsCategory] = useState(selectedNews?.category || '');
    const [selectedImage, setSelectedImage] = useState(selectedNews?.banner || placeHolder);
    const [imageFile, setImageFile] = useState(null);
    const fileInputRef = useRef(null);

    console.log(selectedNews, "news id here")
    const readData = async () => {
        try {
            const details = await AsyncStorage.getItem('userToken');
            const users = await AsyncStorage.getItem('fullName');

            if (details !== null) {
                setBearer(details);
            }
            if (users !== null) {
                setUser(users);
            }
        } catch (e) {
            alert('Failed to fetch the input from storage');
        }
    };

    useEffect(() => {
        readData();
    }, []);

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${bearer}`
    };

    const editNews = async () => {
        setLoading(true);
        try {
            const formData = new FormData();
            formData.append('id', selectedNews?.id);
            formData.append('title', newsTitle);
            formData.append('short_description', shortDescription);
            formData.append('long_description', longDescription);
            formData.append('category', newsCategory);
            if (imageFile) {
                formData.append('banner', imageFile);
            }

            const response = await axios.post(`${BASE_URL}/news/update`, formData, {
                headers: {
                    ...headers,
                    'Content-Type': 'multipart/form-data',
                },
            });

            console.log(response.data.message);
            toast.success(response.data.message);
            navigate('/news_editor');
        } catch (error) {
            let errorMessage = 'An error occurred. Please try again.';
            if (error.response && error.response.data && error.response.data.message) {
                if (typeof error.response.data.message === 'string') {
                    errorMessage = error.response.data.message;
                } else if (Array.isArray(error.response.data.message)) {
                    errorMessage = error.response.data.message.join('; ');
                } else if (typeof error.response.data.message === 'object') {
                    errorMessage = JSON.stringify(error.response.data.message);
                }
            }
            toast.error(errorMessage);
        } finally {
            setLoading(false);
        }
    };

    const handleEditorChange = (content) => {
        setLongDescription(content);
    };

    const handleTitleChange = (event) => {
        setNewsTitle(event.target.value);
    };

    const handleShortDescriptionChange = (event) => {
        setShortDescription(event.target.value);
    };

    const handleCategoryChange = (event) => {
        setNewsCategory(event.target.value);
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedImage(URL.createObjectURL(file));
            setImageFile(file);
        }
    };

    useEffect(() => {
        return () => {
            if (selectedImage && selectedImage !== placeHolder) {
                URL.revokeObjectURL(selectedImage);
            }
        };
    }, [selectedImage]);

    const modules = {
        toolbar: [
            [{ size: [] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            ['image'],
        ],
    };


    return (
        <div>
        <MainDashoard/>

        <div className={classes.finishedbodyCont}>
            <div className={classes.topPadding}>
                <div className={`${classes.formSecCont}`}>
                    <div className={classes.formSectionHeader}>
                        <h3>Departmental News</h3>
                    </div>
                    <div className={classes.formSectionHeader}>
                        {/* <small>Welcome </small> */}
                        <h3 style={{color:'#2D995F'}}>user</h3>
                    </div>
                </div>
            </div>
            
        <div className={classes.mainform}>

    <div className={classes.loandgrantcards}>
    <div className={classes.loandethead}>
        <div className={classes.formLabel}>
            <h4> Edit News</h4>
            {/* <p className={classes.loanText}>Home - Apps - eCommerce - Catalog</p> */}
        </div>
    </div>

    <div className={classes.loanContainer}>
        <div className={classes.loanResponsive}>
            
            <div className={classes.mainForm}>
                <h5>General</h5>
                <Form className={classes.form}>

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Title<span className={classes.span}>*</span></Form.Label>
                        <Form.Control type="text" placeholder="Add Title"
                        value={newsTitle}
                        // value={selectedNews?.title}
                        onChange={handleTitleChange}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Category<span className={classes.span}>*</span></Form.Label>
                            <Form.Select name="customer" className="form-control" required="" 
                            value={newsCategory}
                            // value={selectedNews?.category}
                            onChange={handleCategoryChange}
                            >
                            <option value="1">Entertainment</option>
                            <option value="2">Politics</option>
                                        
                         </Form.Select>
                            
                        </Form.Group>
                        {/* <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>News Type<span className={classes.span}>*</span></Form.Label>
                            <Form.Select name="customer" className="form-control" required="" 
                            value={newsCategory}
                            
                            >
                            <option>General</option>
                            <option>Department</option>
                                        
                         </Form.Select>
                            
                        </Form.Group> */}


                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Short Description<span className={classes.span}>*</span></Form.Label>
                        <Form.Control type="text" placeholder="News Category"
                        value={shortDescription}
                        // value={selectedNews?.short_desc}
                        onChange={handleShortDescriptionChange}
                        />
                    </Form.Group>
                    
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Long description</Form.Label>
                            <ReactQuill 
                                value={longDescription}
                                // value={selectedNews?.long_desc}
                                onChange={handleEditorChange}
                                modules={modules}
                                className={classes.editor}
                                placeholder='Start writing your text here '
                            />
                        </Form.Group>
                </Form>
                    <div className={`${classes.formIntBtn} ${classes.formIntBtn2}`}>
                        <Button variant="light" className={classes.btn1} onClick={() => navigate('/news_editor')}>Cancel</Button>
                        <Button onClick={editNews} variant="success" className={classes.btn2}>
                            {loading ? (
                                <>
                                    <Spinner size='sm' />
                                    <span style={{ marginLeft: '5px' }}>Updating news, please wait...</span>
                                </>
                            ) : (
                                "Update News"
                            )}
                        </Button>
                    </div>

                <div>
                    
                </div>
            </div>
            <div className={classes.banner}>
                <h5>Banner</h5>
                <div className={classes.imgSec}>
                    <div className={classes.imgCont}>
                        <div className={classes.mainImgCont}>
                            <img src={selectedImage} alt='Selected' />
                        </div>
                    </div>
                    <p>Set the product thumbnail image. Only *.png, *.jpg and *.jpeg image files are accepted</p>
                    <div className={classes.imgInput}>
                        <input
                            type="file"
                            accept=".jpg, .jpeg, .png"
                            onChange={handleImageChange}
                            name="image"
                        />
                    </div>
                </div>
                {/* <div className={classes.imgSec}>
                    <div className={classes.imgCont}>
                        <div className={classes.mainImgCont}>
                            <img src={selectedImage} alt='Selected' />
                        </div>
                    </div>
                    <p>Set the product thumbnail image. Only *.png, *.jpg and *.jpeg image files are accepted</p>
                    <div className={classes.imgInput}>
                        <input
                            required=""
                            type="file"
                            accept=".jpg, .jpeg, .png"
                            onChange={handleImageChange}
                            name="image"
                        />
                    </div>
                </div> */}
            </div>    
        </div>
        
    </div>
</div> 

</div>

        </div>

    </div>
)
}