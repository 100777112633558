import React, { useState, useEffect, useRef } from 'react';
import classes from './NewFAQs.module.css';
import RegLogo from '../../Images/RegistrationLogo.svg'
import { Spinner, Badge, Button } from 'react-bootstrap';
import Folder from '../../Images/folder-2.svg';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import ArrowLogo from '../../Images/arrow-left.svg';
import LoanImage from '../../Images/loan bg.svg';
import MainDashoard from '../Main Dashboard/MainDashoard';
import Ready from '../../Images/nothing.svg'
import Ready1 from '../../Images/review.svg';
import { Link, useNavigate, useLocation } from 'react-router-dom'
import Table from 'react-bootstrap/Table';
import placeHolder from "../../assets/imagePlaceHolder.jpg"
// import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import InputGroup from 'react-bootstrap/InputGroup';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // import styles
// import './CustomQuillToolbar.css';
import { BASE_URL } from '../Api/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function EditFAQ() {
    const navigate = useNavigate();
    const [editorHtml, setEditorHtml] = useState('');
    const [inputText, setInputText] = useState('');
    const [imageSrc, setImageSrc] = useState([]);
    const fileInputRef = useRef(null);
    const [bearer, setBearer] = useState('');
    const [user, setUser] = useState('');
    const [loading, setLoading] = useState(false);
    const [banner, setBanner] = useState('');
    const [selectedImage, setSelectedImage] = useState(null);
    const [newsTitle, setNewsTitle] = useState('');
    const [shortDescription, setShortDescription] = useState('');
    const [longDescription, setLongDescription] = useState('');
    const [newsCategory, setNewsCategory] = useState('');

    const location = useLocation();
  const { selectedFaq } = location.state || {};

  const [question, setQuestion] = useState(selectedFaq?.question || '');
  const [answer, setAnswer] = useState(selectedFaq?.answer || '');
//   const [document, setDocument] = useState(selectedContract?.document || '');

    console.log("selected faq :", selectedFaq)
  useEffect(() => {
    if (selectedFaq) {
      setQuestion(selectedFaq.question);
      setAnswer(selectedFaq.answer);
    //   setDocument(selectedContract.document);
    }
  }, [selectedFaq]);
  
  

//   console.log('Selected Contract:', selectedContract);
    // console.log(title, description, document, " Edit details here")

    const readData = async () => {
        try {
          const details = await AsyncStorage.getItem('userToken');
          const users = await AsyncStorage.getItem('fullName');
                 
          if (details !== null) {
            setBearer(details);
          }
          if (users !== null) {
            setUser(users);
          }
    
        } catch (e) {
          alert('Failed to fetch the input from storage');
        }
      };
    
      useEffect(() => {
        readData();
      }, []);
    


    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${bearer}`
      };

      const updateAdvart = async () => {
        setLoading(true);

        try {
            const formData = new FormData();
            formData.append('question', question);
            formData.append('answer', answer);
            formData.append('id', selectedFaq.id);

            // formData.append('advert_id', selectedContract?.id);

            // if (document) {
            //     formData.append('document', document);
            // }

            const response = await axios.post(`${BASE_URL}/faqs/update`, formData, {
                headers: {
                    ...headers,
                    'Content-Type': 'multipart/form-data',
                },
            });
            toast.success(response.data.message);
            setQuestion("");
            setAnswer("");
            // setDocument([]);
            navigate('/faqs');

        } catch (error) {
            let errorMessage = 'An error occurred. Please try again.';
            if (error.response && error.response.data && error.response.data.message) {
                if (typeof error.response.data.message === 'string') {
                    errorMessage = error.response.data.message;
                } else if (Array.isArray(error.response.data.message)) {
                    errorMessage = error.response.data.message.join('; ');
                } else if (typeof error.response.data.message === 'object') {
                    errorMessage = JSON.stringify(error.response.data.message);
                }
            }
            toast.error(errorMessage);
            console.error("Error message:", errorMessage);
        } finally {
            setLoading(false);
        }
    };
      


    const handleQuestionChange = (event) => {
        setQuestion(event.target.value);
    };

    // const handleDocumentChange = (event) => {
    //     const file = event.target.files[0];
    //     if (file) {
    //         // setSelectedImage(URL.createObjectURL(file));
    //         setDocument(file);
    //     }
    // };

    console.log(document, "jjhdh");

    const handleAnswerChange = (event) => {
        setAnswer(event.target.value);
    };

    

    const handleEditorChange = (content, delta, source, editor) => {
    setEditorHtml(content);
  };

  const handleInputChange = (event) => {
    setInputText(event.target.value);
  };
    
  const applyInputText = () => {
    // You can add logic to format the text input as needed
    setEditorHtml(editorHtml + inputText);
    setInputText('');
  };


    const handleGoBack = () => {
        navigate(-1);
    };
  
    

    return (
        <div>
        <MainDashoard />

        <div className={classes.finishedbodyCont}>
            <div className={classes.topPadding}>
                <div className={`${classes.formSecCont}`}>
                    <div className={classes.formSectionHeader}>
                        <h3>Update Question</h3>
                    </div>
                    <div className={classes.formSectionHeader}>
                        {/* <small>Welcome </small> */}
                        <h3 style={{color:'#2D995F'}}>{user}</h3>
                    </div>
                </div>
            </div>
            
        <div className={classes.mainform}>

    <div className={classes.loandgrantcards}>
        <div className={classes.loandethead}>
            {/* <div className={classes.formLabel}>
                <h4>Add New</h4>
                <p className={classes.loanText}>Fill in the details below to add new news...</p>
            </div> */}
            {/* <div className={classes.formIntBtn}>
                <Button variant="light" className={classes.btn1}> Add Member</Button>
                <Button variant="success" className={classes.btn2}>New Campaign</Button>
            </div> */}
        </div>

        <div className={classes.loanContainer}>
            <div className={classes.loanResponsive}>
                
                <div className={classes.mainForm}>
                    {/* <h5>General</h5> */}
                    <Form className={classes.form}>

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Question<span className={classes.span}>*</span></Form.Label>
                            <Form.Control type="text" placeholder="Add Question"
                            value={question}
                            onChange={handleQuestionChange}
                            />
                        </Form.Group>

                        
                        {/* <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Type<span className={classes.span}>*</span></Form.Label>
                            <Form.Select name="customer" className="form-control" required="" 
                            value={newsCategory}
                            onChange={handleCategoryChange}
                            >
                            <option>General</option>
                            <option>Department</option>
                                        
                         </Form.Select>
                            
                        </Form.Group> */}

                        {/* <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Description<span className={classes.span}>*</span></Form.Label>
                            <Form.Control type="text" placeholder="Enter Description"
                            value={description}
                            onChange={handleDescriptionChange}/>
                        </Form.Group> */}

                        {/* <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Update FAQ<span className={classes.span}>*</span></Form.Label>
                            <Form.Control 
                            
                            required
                            type="file"
                            accept=".pdf"
                            onChange={handleAnswerChange}
                            name="file"
                            />
                            
                        </Form.Group> */}

                        {/* <div className={classes.banner}>
                    <h5>Upload Document</h5>
                    <div className={classes.imgSec}>
                        <div className={classes.imgCont}>
                            <div className={classes.mainImgCont}>
                                <>
                                {selectedImage && <img src={selectedImage} alt='image' />}
                                </>
                            
                            </div>
                        </div>
                        <p>Set the product thumbnail image. Only *.pdf files are accepted</p>
                        <div className={classes.imgInput}>
                            <input
                            required=""
                            type="file"
                            accept=".pdf"
                            onChange={handleImageChange}
                            name="file"
                          />
                        </div>
                    </div>
                </div>     */}

<Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Answer<span className={classes.span}>*</span></Form.Label>
                            {/* <Form.Control type="text-area" placeholder="Enter Answer"
                            value={answer}
                            onChange={handleAnswerChange}/> */}
                            <Form.Control as="textarea" rows={3} placeholder="Enter Answer"
                            value={answer}
                            onChange={handleAnswerChange} />
                        </Form.Group>

                        {/* <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Enter File Link<span className={classes.span}>*</span></Form.Label>
                            <Form.Control type="text" placeholder="Enter Link"
                            value={shortDescription}
                            onChange={handleShortDescriptionChange}/>
                        </Form.Group> */}

                        {/* <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Long Description<span className={classes.span}>*</span></Form.Label>
                            <Form.Control type="text" placeholder="News Status"/>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Category<span className={classes.span}>*</span></Form.Label>
                            <Form.Control type="text" placeholder="Registration Name"/>
                        </Form.Group> */}

                        
                    </Form>

                    <div>
                        {/* <ReactQuill 
                            value={editorHtml}
                            onChange={handleEditorChange}
                            modules={modules}
                        /> */}
                        {/* <input 
                            type="text"
                            value={inputText}
                            onChange={handleInputChange}
                            placeholder="Enter text to format"
                        /> */}
                        {/* <button onClick={applyInputText}>Apply Text</button> */}
                    </div>
                </div>
               
            </div>
            <div className={`${classes.formIntBtn} ${classes.formIntBtn2}`}>
                <Button variant="light" className={classes.btn1} onClick={handleGoBack}> Cancel</Button>
                <Button variant="success" className={classes.btn2} onClick={updateAdvart}>
                    {loading ? (
                        <>
                            <Spinner size='sm' />
                            <span style={{ marginLeft: '5px' }}>Uploading, Please wait...</span>
                        </>
                    ) : (
                        "Update"
                    )}
                </Button>
            </div>
            {/* <div className={classes.footerCont}>
                <div>
                    <small>2024© <Link to={'#'}> Ogun State</Link></small>
                </div>
                <div>
                    <small className={classes.small}>
                        <Link to={'#'}>About</Link>
                        <Link to={'#'}>Support</Link>
                        <Link to={'#'}>Purchase</Link>
                    </small>
                </div>
            </div> */}
        </div>
    </div> 

</div>

        </div>

    </div>
)
}