import React, { useState, useEffect } from 'react';
import classes from './ViewDeptNews.module.css';
import RegLogo from '../../Images/RegistrationLogo.svg'
import { Spinner, Badge, Button } from 'react-bootstrap';
import Folder from '../../Images/folder-2.svg';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import ArrowLogo from '../../Images/arrow-left.svg';
import LoanImage from '../../Images/loan bg.svg';
import MainDashoard from '../Main Dashboard/MainDashoard';
import Ready from '../../Images/nothing.svg'
import Ready1 from '../../Images/review.svg';
import { Link, useNavigate, useLocation } from 'react-router-dom'
import Table from 'react-bootstrap/Table';
import rectangle from '../../Images/Rectangle 9.png';



export default function ViewDeptNews() {

    const navigate = useNavigate();
    const [bearer, setBearer] = useState('');
    const [user, setUser] = useState('');
    const location = useLocation();
    const { selectedNewss } = location.state || {};

    const [newsTitle, setNewsTitle] = useState(selectedNewss?.title);
    const [shortDescription, setShortDescription] = useState(selectedNewss?.short_desc);
    const [longDescription, setLongDescription] = useState(selectedNewss?.long_desc);
    const [newsCategory, setNewsCategory] = useState(selectedNewss?.category);
    const [selectedImage, setSelectedImage] = useState(selectedNewss?.banner);
    // const fileInputRef = useRef(null);
    
    console.log(selectedNewss, "news here")
    
    const readData = async () => {
        try {
          const details = await AsyncStorage.getItem('userToken');
          const users = await AsyncStorage.getItem('fullName');
                 
          if (details !== null) {
            setBearer(details);
          }
          if (users !== null) {
            setUser(users);
          }
    
        } catch (e) {
          alert('Failed to fetch the input from storage');
        }
      };
    
      useEffect(() => {
        readData();
      }, []);

    const goBack = () =>{
        navigate('/news_editor')
    }

    return (
        <div>
        <MainDashoard />

        <div className={classes.finishedbodyCont}>
        <div className={classes.topPadding}>
                <div className={`${classes.formSecCont}`}>
                    <div className={classes.formSectionHeader}>
                        <h3>News Editor</h3>
                    </div>
                    <div>
                        {/* <small>Welcome </small> */}
                        <h3 style={{color:'#2D995F'}}>{user}</h3>
                    </div>
                </div>
            </div>
            
        <div className={classes.mainform}>

    <div className={classes.loandgrantcards}>
    <div className={classes.loandethead} onClick={goBack}>
        <Button variant='light'><i class='bx bx-arrow-back'></i> Go Back</Button>
    </div>

    <div className={classes.loanContainer}>
        <h4>{newsTitle}</h4>
        <p className={classes.firstP}>{shortDescription}</p>
    <img src ={selectedImage} width={970} height={288} />
    <p  dangerouslySetInnerHTML={{ __html:longDescription}} className={classes.secondP}></p>



    <div className={classes.btnEdit}>
        <button onClick={()=>navigate('/edit_news')}>Edit news in news editor</button>
    </div>
        </div>
    </div> 

    </div>

        </div>

    </div>
)
}