import React, { useState, useEffect } from 'react';
import classes from './SubNews.module.css';
import RegLogo from '../../Images/RegistrationLogo.svg'
import { Spinner, Badge, Button, Modal, Form } from 'react-bootstrap';
import Folder from '../../Images/folder-2.svg';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import ArrowLogo from '../../Images/arrow-left.svg';
import LoanImage from '../../Images/loan bg.svg';
import MainDashoard from '../Main Dashboard/MainDashoard';
import Ready from '../../Images/nothing.svg'
import Ready1 from '../../Images/review.svg';
import { Link, useNavigate, useLocation } from 'react-router-dom'
import Table from 'react-bootstrap/Table';
import rectangle from '../../Images/Rectangle 9.png';
import { BASE_URL } from '../Api/Api';
import { toast, ToastContainer } from 'react-toastify';


export default function ViewGallery() {

    const navigate = useNavigate();
    const [bearer, setBearer] = useState('');
    const [user, setUser] = useState('');
    const location = useLocation();
    const { selectedGallery } = location.state || {};

    const [newsTitle, setNewsTitle] = useState(selectedGallery?.title);
    const [shortDescription, setShortDescription] = useState(selectedGallery?.short_desc);
    const [longDescription, setLongDescription] = useState(selectedGallery?.long_desc);
    const [newsCategory, setNewsCategory] = useState(selectedGallery?.category);
    const [selectedImage, setSelectedImage] = useState(selectedGallery?.file_path);
    const [show, setShow] = useState(false);
    const [approvalLoading, setApprovalLoading] = useState(false);
    const [disapprove, setDisapprove] = useState(false);
    const [roleId, setRoleId] = useState('');
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [reason, setReason] = useState('');
    // const fileInputRef = useRef(null);
    
    console.log(selectedGallery, "news here")
    
    const readData = async () => {
        try {
          const details = await AsyncStorage.getItem('userToken');
          const users = await AsyncStorage.getItem('fullName');
          const detailss = await AsyncStorage.getItem('roleId');   
          
          if (details !== null) {
            setBearer(details);
          }
          if (users !== null) {
            setUser(users);
          }
          if (detailss !== null) {
            setRoleId(detailss);
        }
    
        } catch (e) {
          alert('Failed to fetch the input from storage');
        }
      };
    
      useEffect(() => {
        readData();
      }, []);

    const goBack = () =>{
        navigate(-1)
    }

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${bearer}`
  };


  const handleApproval = async () => {
    setApprovalLoading(true);
    try {
        const response = await axios.post(
            `${BASE_URL}/media/approve`,
            { id: selectedGallery.id },
            { headers }
        );
        navigate('/gallery');
        toast.success(response.data.message);
    } catch (error) {
        let errorMessage = 'An error occurred. Please try again.';
        if (error.response && error.response.data && error.response.data.message) {
            if (typeof error.response.data.message === 'string') {
                errorMessage = error.response.data.message;
            } else if (Array.isArray(error.response.data.message)) {
                errorMessage = error.response.data.message.join('; ');
            } else if (typeof error.response.data.message === 'object') {
                errorMessage = JSON.stringify(error.response.data.message);
            }
        }
        toast.error(errorMessage);
        console.log(errorMessage);
    } finally {
        setApprovalLoading(false);
    }
};

const handleDisapprove = async () => {
    setDisapprove(true);
    try {
        const response = await axios.post(
            `${BASE_URL}/disapprove-news`,
            { id: selectedGallery.id, 
              reason: reason },
            { headers }
        );
        navigate('/gallery');
        toast.success(response.data.message);
    } catch (error) {
        let errorMessage = 'An error occurred. Please try again.';
        if (error.response && error.response.data && error.response.data.message) {
            if (typeof error.response.data.message === 'string') {
                errorMessage = error.response.data.message;
            } else if (Array.isArray(error.response.data.message)) {
                errorMessage = error.response.data.message.join('; ');
            } else if (typeof error.response.data.message === 'object') {
                errorMessage = JSON.stringify(error.response.data.message);
            }
        }
        toast.error(errorMessage);
        console.log(errorMessage);
    } finally {
        setDisapprove(false);
    }
};


const renderApprovalButtons = () => {
    if (selectedGallery.approval_status === "1" && roleId === "13") {
        return (
            <div className={classes.BottomButton}>
                <div onClick={handleApproval} className={classes.btnApprove}>
                    <button>
                        {approvalLoading ? (
                            <>
                                <Spinner size="sm" />
                                <span style={{ marginLeft: '5px' }}>Approving, Please wait...</span>
                            </>
                        ) : (
                            "Approve Gallery"
                        )}
                    </button>
                </div>
                <div className={classes.btnApprove1}>
                    <button onClick={handleShow}>Disapprove Gallery</button>
                </div>
            </div>
        );
    } 
    return null;
};

const timestamp = selectedGallery?.created_at;
const dateObj = new Date(timestamp);

const options = { 
    day: 'numeric', 
    month: 'long', 
    year: 'numeric', 
    hour: 'numeric', 
    minute: 'numeric', 
    hour12: true 
};

const day = dateObj.getDate();
const dayWithSuffix = `${day}${(day % 10 === 1 && day !== 11) ? 'st' : (day % 10 === 2 && day !== 12) ? 'nd' : (day % 10 === 3 && day !== 13) ? 'rd' : 'th'}`;

const formattedDate = `${dayWithSuffix} ${dateObj.toLocaleDateString('en-US', { month: 'long', year: 'numeric' })}`;


const formattedTime = dateObj.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });


const formattedTimestamp = `${formattedDate}, ${formattedTime}`;


    return (
        <div>
            <ToastContainer />
        <MainDashoard />

        <div className={classes.finishedbodyCont}>
        <Modal show={show} onHide={handleClose} animation={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>Disapprove News</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form style={{ marginTop: 20 }}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Reason</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Reason for disapproving"
                                    value={reason}
                                    onChange={(e) => setReason(e.target.value)}
                                />
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={handleClose}>
                            Go back
                        </Button>
                        <Button variant="success" onClick={handleDisapprove}>
                            {disapprove ? (
                                <>
                                    <Spinner size="sm" />
                                    <span style={{ marginLeft: '5px' }}>Disapproving, Please wait...</span>
                                </>
                            ) : (
                                "Disapprove News"
                            )}
                        </Button>
                    </Modal.Footer>
                </Modal>
        <div className={classes.topPadding}>
                <div className={`${classes.formSecCont}`}>
                    <div className={classes.formSectionHeader}>
                        <h3>View Gallery</h3>
                    </div>
                    <div>
                        {/* <small>Welcome </small> */}
                        <h3 style={{color:'#2D995F'}}>{user}</h3>
                    </div>
                </div>
            </div>
            
        <div className={classes.mainform}>

    <div className={classes.loandgrantcards}>
    <div className={classes.loandethead} onClick={goBack}>
        <Button variant='light'><i class='bx bx-arrow-back'></i> Go Back</Button>
    </div>

    <div className={classes.loanContainer}>
        <h4>{newsTitle}</h4>
        <p style={{marginTop: -20}}>{formattedTimestamp}</p>

        <p className={classes.firstP}>{shortDescription}</p>
    <img src ={selectedImage} style={{width: "100%", height: 500, objectFit: "contain"}}  />
   



   
    {selectedGallery.approval_status !== "2" && renderApprovalButtons()}
        </div>
    </div> 
    </div>

        </div>

    </div>
)
}