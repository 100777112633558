import React, { useState, useEffect } from 'react';
import classes from './ManageExecutives.module.css';
import RegLogo from '../../Images/RegistrationLogo.svg'
import { Spinner, Badge, Button, Modal, Form } from 'react-bootstrap';
import Folder from '../../Images/folder-2.svg';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import ArrowLogo from '../../Images/arrow-left.svg';
import LoanImage from '../../Images/loan bg.svg';
import MainDashoard from '../Main Dashboard/MainDashoard';
import Ready from '../../Images/nothing.svg'
import Ready1 from '../../Images/review.svg';
import { Link, useNavigate } from 'react-router-dom'
import Table from 'react-bootstrap/Table';
import { BASE_URL } from '../Api/Api';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';


export default function ManageExecutives() {
  const navigate = useNavigate();
  const [bearer, setBearer] = useState('');
  const [description, setDescription] = useState('');
  const [commissionersName, setCommissionersName] = useState('');
  const [commissionersProfile, setCommissionersProfile] = useState('');
  const [selectedCommissionerPhoto, setSelectedCommissionersPhoto] = useState(null);
  const [description1, setDescription1] = useState('');
  const [commissionersName1, setCommissionersName1] = useState('');
  const [commissionersProfile1, setCommissionersProfile1] = useState('');
  const [selectedCommissionerPhoto1, setSelectedCommissionersPhoto1] = useState(null);
  const [user, setUser] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const [fullName, setFullName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [imageSrc, setImageSrc] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [selectedType1, setSelectedType1] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [roleLoading, setRoleLoading] = useState(false);
  const [tableData1, setTableData1] = useState([]);
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalEntries, setTotalEntries] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [selectedMDA, setSelectedMDA] = useState("");
  const [editClicked, setEditClicked] = useState(false);



  const handleCreateNew = () => {
    navigate('/create_executives');
  }

  const readData = async () => {
    try {
      const detail = await AsyncStorage.getItem('fullName');
      const details = await AsyncStorage.getItem('userToken');


      if (detail !== null) {
        setUser(detail);
      }


      if (details !== null) {
        setBearer(details);
      }
    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  useEffect(() => {
    readData();
  }, []);

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };



  const fetchMDAs = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/get-executives-list?page=${currentPage}`, { headers });
      const results = response.data?.data?.data;
      const resultx = response.data?.data?.total;
      setTotalEntries(resultx);
      setTableData(results);
      const total = response.data?.data?.last_page || 1;
      setTotalPages(total);
      console.log(results);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page if unauthorized
        navigate('/login');
      } else {
        const errorStatus = error.response?.data?.message;
        console.log(errorStatus);
        setTableData([]);
      }
    } finally {
      setIsLoading(false);
    }
  };



  useEffect(() => {
    if (bearer) {
      fetchMDAs();
    }
  }, [bearer, currentPage]);

  const fetchRole = async () => {
    setRoleLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/role/get-roles`, { headers });
      const results = response.data?.data;
      setTableData1(results);
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';
      if (error.response && error.response.data && error.response.data.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
      }
      toast.error(errorMessage);
    } finally {
      setRoleLoading(false);
    }
  };



  useEffect(() => {
    if (bearer) {
      fetchRole();
    }
  }, [bearer]);

  const handleEditClick = (id) => {
    const foundExecutive = tableData.find(item => item.id === id);
     console.log(foundExecutive);
    navigate('/edit_executives', { state: { selectedExecutive: foundExecutive } });
    setEditClicked(true);
  };

  const handleRoleChange = (event) => {
    setSelectedRole(event.target.value);
  };
  const handleTypeChange = (event) => {
    setSelectedType(event.target.value);
  };
  const handleTypeChange1 = (event) => {
    setSelectedType1(event.target.value);
  };

  // const createMDA = async () => {
  //   setLoading(true);
  //   try {
  //     const formData = new FormData();
  //     formData.append('description', description);
  //     formData.append('mda_type', selectedType);
  //     formData.append('commisioners_name', commissionersName);
  //     formData.append('commisioners_profile', commissionersProfile);
  //     selectedCommissionerPhoto.forEach((file) => {
  //       formData.append('commisioners_image', file);
  //     });
  //     const response = await axios.post(
  //       `${BASE_URL}/create-mda`,
  //       formData,
  //       {
  //         headers: {
  //           ...headers,
  //           'Content-Type': 'multipart/form-data',
  //         },
  //       }
  //     );
  //     console.log(response)
  //     fetchMDAs();
  //     handleClose();
  //     setCommissionersName('');
  //     setDescription('');
  //     setCommissionersProfile('');
  //     setSelectedCommissionersPhoto(null);
  //     setSelectedType('');
  //     toast.success(response.data.message);
  //     console.log(response.data);

  //   } catch (error) {
  //     let errorMessage = 'An error occurred. Please try again.';
  //     if (error.response && error.response.data && error.response.data.message) {
  //       if (typeof error.response.data.message === 'string') {
  //         errorMessage = error.response.data.message;
  //       } else if (Array.isArray(error.response.data.message)) {
  //         errorMessage = error.response.data.message.join('; ');
  //       } else if (typeof error.response.data.message === 'object') {
  //         errorMessage = JSON.stringify(error.response.data.message);
  //       }
  //     }
  //     toast.error(errorMessage);
  //     setLoading(false);
  //   }
  // };


  // const updateMDA = async () => {
  //   setLoad(true);
  //   try {
  //     const formData = new FormData();
  //     formData.append('description', description1);
  //     formData.append('id', selectedMDA);
  //     formData.append('mda_type', selectedType1);
  //     formData.append('commisioners_name', commissionersName1);
  //     formData.append('commisioners_profile', commissionersProfile1);
  //     // formData.append('commisioners_image', selectedCommissionerPhoto1);
  //     selectedCommissionerPhoto1.forEach((file) => {
  //       formData.append('commisioners_image', file);
  //     });
  //     console.log('FormData:', formData);

  //     const response = await axios.post(
  //       `${BASE_URL}/update-mda`,
  //       formData,
  //       {
  //         headers: {
  //           ...headers,
  //           'Content-Type': 'multipart/form-data',
  //         },
  //       }
  //     );
  //     console.log('Response:', response);

  //     handleClose1();
  //     fetchMDAs();
  //     setDescription1('');
  //     setCommissionersName1('');
  //     setCommissionersProfile1('');
  //     setSelectedCommissionersPhoto1(null);
  //     setSelectedType1('');
  //     toast.success(response.data.message);
  //     console.log(response.data);

  //   } catch (error) {
  //     console.error('Error:', error); // Added error logging
  //     let errorMessage = 'An error occurred. Please try again.';
  //     if (error.response && error.response.data && error.response.data.message) {
  //       if (typeof error.response.data.message === 'string') {
  //         errorMessage = error.response.data.message;
  //       } else if (Array.isArray(error.response.data.message)) {
  //         errorMessage = error.response.data.message.join('; ');
  //       } else if (typeof error.response.data.message === 'object') {
  //         errorMessage = JSON.stringify(error.response.data.message);
  //       }
  //     }
  //     toast.error(errorMessage);
  //   } finally {
  //     setLoad(false);
  //   }
  // };

  const handleImageChange = (event) => {
    const files = event.target.files;
    setSelectedCommissionersPhoto(Array.from(files));
  };
  const handleImageChange1 = (event) => {
    const files = event.target.files;
    setSelectedCommissionersPhoto1(Array.from(files));
  };

  const handlePrevPage = () => {
    setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
  };


  const handleEyeClick = (id) => {
    const foundMDA = tableData.find(item => item.id === id);
    if (foundMDA) {
      const { name, commisioners_name, commisioners_profile, commisioners_image, mda_type } = foundMDA;
      setSelectedMDA(id);
      console.log(id);
      setDescription1(name || '');
      setCommissionersName1(commisioners_name || '');
      setCommissionersProfile1(commisioners_profile || '');
      setSelectedCommissionersPhoto1(commisioners_image || '');
      setSelectedType1(mda_type || '');

      setShow1(true);
    } else {
      console.error(`User with id ${id} not found`);
    }
  };

  const handleTrashClick = async (id) => {
    try {
      const confirmed = await Swal.fire({
        title: 'Are you sure?',
        text: 'You are about to delete this Executive.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, cancel',
      });

      if (!confirmed.isConfirmed) {
        return;
      }

      const response = await axios.get(`${BASE_URL}/delete-mda?id=${id}`, { headers });
      fetchMDAs();
      toast.success(response.data.message);
    } catch (error) {
      let errorMessage = 'An error occurred. Please try again.';

      if (error.response?.data?.message) {
        if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        } else if (Array.isArray(error.response.data.message)) {
          errorMessage = error.response.data.message.join('; ');
        } else if (typeof error.response.data.message === 'object') {
          errorMessage = JSON.stringify(error.response.data.message);
        }
      }

      toast.error(errorMessage);
    }
  };



  return (
    <div>
      <MainDashoard />

      <div className={classes.finishedbodyCont}>
        <div className={classes.topPadding}>
          <div className={`${classes.formSecCont}`}>
            <div className={classes.formSectionHeader}>
              <h3>Manage Executives</h3>
            </div>
            <div className={classes.formSectionHeader}>
              <h3 style={{ color: '#2D995F' }}>{user}</h3>
            </div>
          </div>
        </div>


        <div className={classes.mainform}>

          <div className={classes.loandgrantcards}>
            {/* <div className={classes.loandethead}>
        <p className={classes.loanText}>Post news on the website from the editor</p>
        <Button variant="light" onClick={addNews}><i class='bx bx-plus-circle'></i> Add New</Button>
    </div> */}
            <div className={classes.loandethead}>
              <div className={classes.formLabel}>
                {/* <h4 style={{color:'#2D995F'}}>Manage User</h4>
            <p className={classes.loanText}>Create and update your users...</p> */}
                {/* <p className={classes.loanText}>Home - Apps - eCommerce - Catalog</p> */}
              </div>
              <div className={classes.formIntBtn}>
                {/* <Button variant="light" className={classes.btn1}> Add Member</Button> */}
                <Button variant="success" onClick={(handleCreateNew)} className={classes.btn2}>Add New Executive</Button>
              </div>
            </div>

            <div className="d-flex justify-content-between align-items-center" style={{ padding: '20px 0 0 0', marginBottom: 20 }}>
              <div className={classes.greenbtn} style={{ display: 'flex', gap: '20px' }}>
                <div className={classes.actionsBtns}>
                  <Button variant='success'>Copy</Button>
                  <Button variant='success'>Excel</Button>
                  <Button variant='success'>PDF</Button>
                  <Button variant='success'>Column visibility</Button>
                </div>
                <div>
                  <label className="d-flex justify-content-start align-items-center">
                    Show
                    <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" className="custom-select custom-select-sm form-control form-control-sm"
                    //  value={entriesPerPage}
                    //     onChange={(e) => {
                    //     setEntriesPerPage(parseInt(e.target.value));
                    //     setCurrentPage(1);
                    //     }}
                    >
                      <option value={10}>10</option>
                      <option value={25}>25</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                    </select>
                    entries
                  </label>
                </div>
              </div>
              <div className="text-right modal-effect ">
                <div id="DataTables_Table_0_filter" className="dataTables_filter">
                  <div className="d-flex justify-content-start align-items-center">
                    <div className="mr-2">Search:</div>
                    <input
                      type="search"
                      // value={searchTerm}
                      className="form-control form-control-sm"
                      placeholder=""
                      aria-controls="DataTables_Table_0"
                    // onChange={(e) => {
                    // setSearchTerm(e.target.value);


                    // }}
                    />
                  </div>

                </div>
              </div>
            </div>

            {isLoading ? (
              <p className={classes.fetchedTxt}>Fetching Executives...</p>
            ) : (
              <div className={classes.loanContainer}>
                <div className={classes.loanResponsive}>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>S/N</th>
                        <th>Name</th>
                        <th>Portfolio</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableData.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{item.commisioners_name}</td>
                          <td>{item.portfolio}</td>
                          <td className={classes.edit}>
                            <div style={{ display: 'flex', gap: '10px' }}>
                              <div onClick={() => handleEditClick(item.id)}>
                                <i class='bx bx-edit-alt' ></i>
                              </div>
                              {/* <div onClick={() => handleTrashClick(item.id)}>
                                <i class='bx bxs-trash' style={{ color: 'red' }}></i>
                              </div> */}
                            </div>
                          </td>
                        </tr>
                      ))}


                    </tbody>
                  </Table>
                  <div className={classes.accrodBtns}>
                    <Button onClick={handlePrevPage} variant='light' className={classes.prev}>Previous</Button>
                    <p style={{ color: '#2D995F' }}>{currentPage} of {totalPages}</p>
                    <Button onClick={handleNextPage} variant="success" className={classes.next}>Next</Button>
                  </div>

                </div>
              </div>
            )}
          </div>

        </div>
        <Modal show={show} onHide={handleClose} animation={false}>
          <Modal.Header closeButton>
            <Modal.Title>Add MDA</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form style={{ marginTop: 20 }}>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <div style={{ marginTop: 10 }} />
                <Form.Label>MDA Type</Form.Label>
                <Form.Select
                  className="form-control"
                  as="select"
                  value={selectedType}
                  onChange={handleTypeChange}
                >
                  <option value="" disabled>Select Type</option>
                  <option value="1">Ministry</option>
                  <option value="2">Department</option>
                  <option value="3">Agencies</option>

                </Form.Select>
                <div style={{ marginTop: 10 }} />
                <Form.Label>Description</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Description"
                  // autoFocus
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
                <div style={{ marginTop: 10 }} />
                <Form.Label>Commissioner's Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Commissioner's Name"
                  // autoFocus
                  value={commissionersName}
                  onChange={(e) => setCommissionersName(e.target.value)}
                />
                <div style={{ marginTop: 10 }} />
                <Form.Label>Commissioner's Profile</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Enter Commissioner's Profile"
                  value={commissionersProfile}
                  onChange={(e) => setCommissionersProfile(e.target.value)}
                />
                <div style={{ marginTop: 10 }} />
                <Form.Label>Commissioner's Photo
                </Form.Label>
                <Form.Control
                  type="file"
                  placeholder="Select photo"
                  // autoFocus
                  accept=".jpg, .jpeg, .png"
                  onChange={handleImageChange}
                  name="image"
                />

              </Form.Group>
            </Form>
          </Modal.Body>






          <Modal.Footer>
            <Button variant="danger" onClick={handleClose}>
              Go back
            </Button>
            {/* <Button variant="success" onClick={createMDA}>
              {loading ? (
                <>
                  <Spinner size='sm' />
                  <span style={{ marginLeft: '5px' }}>Creating, Please wait...</span>
                </>
              ) : (
                "Create MDA"
              )}
            </Button> */}
          </Modal.Footer>
        </Modal>
        <Modal show={show1} onHide={handleClose1} animation={false}>
          <Modal.Header closeButton>
            <Modal.Title>Add MDA</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form style={{ marginTop: 20 }}>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <div style={{ marginTop: 10 }} />
                <Form.Label>MDA Type</Form.Label>
                <Form.Select
                  className="form-control"
                  as="select"
                  value={selectedType1}
                  onChange={handleTypeChange1}
                >
                  <option value="" disabled>Select Type</option>
                  <option value="1">Ministry</option>
                  <option value="2">Department</option>
                  <option value="3">Agencies</option>

                </Form.Select>
                <div style={{ marginTop: 10 }} />
                <Form.Label>Description</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Description"
                  // autoFocus
                  value={description1}
                  onChange={(e) => setDescription1(e.target.value)}
                />
                <div style={{ marginTop: 10 }} />
                <Form.Label>Commissioner's Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Commissioner's Name"
                  // autoFocus
                  value={commissionersName1}
                  onChange={(e) => setCommissionersName1(e.target.value)}
                />
                <div style={{ marginTop: 10 }} />
                <Form.Label>Commissioner's Profile</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Enter Commissioner's Profile"
                  value={commissionersProfile1}
                  onChange={(e) => setCommissionersProfile1(e.target.value)}
                />
                <div style={{ marginTop: 10 }} />
                <Form.Label>Commissioner's Photo
                </Form.Label>
                <Form.Control
                  type="file"
                  placeholder="Select photo"
                  // autoFocus
                  accept=".jpg, .jpeg, .png"
                  onChange={handleImageChange1}
                  name="image"
                />

              </Form.Group>
            </Form>
          </Modal.Body>






          <Modal.Footer>
            <Button variant="danger" onClick={handleClose1}>
              Go back
            </Button>

            {/* <Button variant="success" onClick={() => updateMDA()}>
              {load ? (
                <>
                  <Spinner size='sm' />
                  <span style={{ marginLeft: '5px' }}>Updating, Please wait...</span>
                </>
              ) : (
                "Update MDA"
              )}
            </Button> */}

          </Modal.Footer>
        </Modal>

      </div>

    </div>
  )
}