import React, { useState, useEffect } from 'react';
import classes from '../../Web pages/Main Dashboard/MinDashboard.module.css';
import RegLogo from '../../Images/RegistrationLogo.svg'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Tab, Tabs, Form, Spinner } from 'react-bootstrap';
import Folder from '../../Images/folder-2.svg';
import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';
import SuccessImg from '../../Images/completed.svg';
import messageIcon from '../../Images/Dashbord-menu-icons/message-text.svg';
import Invoice from '../../Images/Dashbord-menu-icons/invoice.svg';
import LogOutIcon from '../../Images/Dashbord-menu-icons/logout.svg';
import DashImg from '../../Images/DI-mobile1.svg';
import Msg1 from '../../Images/DI-mobile2.svg';
import Inv from '../../Images/DI-mobile3.svg';
import LgOut from '../../Images/DI-mobile4.svg';
import DashboardLogo from '../../Images/dashboardLogo.svg';
import UserLogo from '../../Images/user-edit.svg';
import Swal from 'sweetalert2';

import { useContext } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import AccordionContext from 'react-bootstrap/AccordionContext';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import Card from 'react-bootstrap/Card';
import OgunlOGO from "../../assets/OgunLogo.svg"


export default function MainDashoard() {
    const location = useLocation();
    const navigate = useNavigate();
    const [bearer, setBearer] = useState('');
    const [permissions, setPermissions] = useState([]);
    const [user, setUser] = useState('');
    const [activeLink, setActiveLink] = useState(null);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isMenuOpen1, setIsMenuOpen1] = useState(false);
    const [isMenuOpen2, setIsMenuOpen2] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);


    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };
    const toggleMenu1 = () => {
        setIsMenuOpen1(!isMenuOpen1); 
    };
    const toggleMenu2 = () => {
        setIsMenuOpen2(!isMenuOpen2); 
    };

    const LIGHTGREEN = '#2D995F'; // Adjust the colors as needed
    const DEEPGREEN = ' #164B2E';

    const ContextAwareToggle = ({ children, eventKey, callback }) => {
        const { activeEventKey } = useContext(AccordionContext);
    
        const decoratedOnClick = useAccordionButton(
            eventKey,
            () => callback && callback(eventKey)
        );
    
        const isCurrentEventKey = activeEventKey === eventKey;
    
        return (
            <button
                type="button"
                // style={{ backgroundColor: isCurrentEventKey ? LIGHTGREEN : DEEPGREEN }}
                onClick={decoratedOnClick}
            >
                {children}
            </button>
        );
    };
    
    const MyAccordionComponent = () => {
        const [isMenuOpen1, setIsMenuOpen1] = useState(false);
        const toggleMenu1 = () => {
            setIsMenuOpen1(!isMenuOpen1);
        }
        };


    const closeMenu = () => {
        setIsMenuOpen(false); // Close the menu
    };
    // const [isReg, setIsReg] = useState(false);


    useEffect(() => {
        const pathname = location.pathname;
        if (pathname.includes('dashboard')) {
            setActiveLink('Dashboard');
        } else if (pathname.includes('news_editor')) {
            setActiveLink('NewsEditor');
        } else if (pathname.includes('media')) {
            setActiveLink('Media');
        } else if (pathname.includes('my_profile')) {
            setActiveLink('My Profile');
        } else if (pathname.includes('gallery')) {
            setActiveLink('Gallery');
        } else if (pathname.includes('contract')) {
            setActiveLink('Contract');
        } else if (pathname.includes('mda_service')) {
            setActiveLink('MDAService');
        } else if (pathname.includes('faqs')) {
            setActiveLink('FAQs');
        } else if (pathname.includes('configuration')) {
            setActiveLink('Configuration');
        } else if (pathname.includes('profile')) {
            setActiveLink('Profile');
        } else if (pathname === '/sign_in') {
            setActiveLink('Logout');
        } else {
            setActiveLink(null);
        }
    }, [location]);

    const readData = async () => {
        try {
            const detail = await AsyncStorage.getItem('fullName');
            const details = await AsyncStorage.getItem('userToken');
            const detailss = await AsyncStorage.getItem('permissions');
            const admin = await AsyncStorage.getItem('admin');


            if (detail !== null) {
                const firstName = detail.split(' ')[0];
                setUser(firstName);
            }


            if (details !== null) {
                setBearer(details);
            }

            if (detailss !== null) {
                setPermissions(detailss);
                
            }

            if (admin !== null) {
                setIsAdmin(admin);
            }

           
        } catch (e) {
            alert('Failed to fetch the input from storage');
        }
    };

    useEffect(() => {
        readData();
    }, []);


    
    const handleLogout = async () => {
        setLoading(true);
        try {
            const response = await axios.post(
                'https://api-smesupport.ogunstate.gov.ng/api/logout',
                {},
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${bearer}`
                    }
                }
            );
            navigate('/sign_in');


        } catch (error) {
            let errorMessage = 'An error occurred. Please try again.';
            if (error.response && error.response.data && error.response.data.message) {
                if (typeof error.response.data.message === 'string') {
                    errorMessage = error.response.data.message;
                } else if (Array.isArray(error.response.data.message)) {
                    errorMessage = error.response.data.message.join('; ');
                } else if (typeof error.response.data.message === 'object') {
                    errorMessage = JSON.stringify(error.response.data.message);
                }
                if (errorMessage.toLowerCase().includes('unauthenticated') || errorMessage.toLowerCase().includes('unauthorized')) {
                    navigate('/sign_in');
                    return;
                }
            }
            setErrorMessage(errorMessage);
        } finally {
            setLoading(false);
        }
    };



    return (
        <div className={classes.sideNavBody}>
            <div className={classes.sideNav}>
                <div className={classes.logoCont}>
                <img src={OgunlOGO} alt='Ogun Logo' className={classes.imageCont}/>
                    <p style={{color:'white'}}>Ogun State Admin Panel</p>
                    {/* <img src={RegLogo} alt='Logo' className={`${classes.img} ${classes.webshow}`} />
                    <img src={DashboardLogo} alt='Logo' className={`${classes.img} ${classes.mobileshow}`} /> */}
                </div>
                {/* {`${classes.mainMenu} ${isMenuOpen ? classes.menuOpen : ''}`} */}
                <div className={classes.menuCont}>
                    <div className={`${classes.regMenu} ${isMenuOpen ? '' : classes.menuOpen}`}>
                
                        <Link
                            to={'/dashboard'}
                            className={activeLink === 'Dashboard' ? classes.active : ''}
                        >
                            <p>
                                <img src={messageIcon} alt='icon' className={classes.webshow} />
                                <img src={DashImg} alt='icon' className={classes.mobileshow} />
                                Dashboard</p>
                        </Link>
                        <Accordion>
                        {isAdmin === "admin" && (
                        <Card className={`${classes.accordionCard} ${activeLink === 'Configuration' ? classes.active : ''}`}>
                                <Card.Header className={classes.cardHeader} onClick={toggleMenu1}>
                                    <ContextAwareToggle  eventKey="0">
                                        <p className={classes.padding}>
                                            <span><img src={messageIcon} alt='icon' /> Configuration</span>
                                            {isMenuOpen1 ?  (<i class='bx bx-chevron-up'></i>): (<i class='bx bx-chevron-down'></i>)} 
                                        </p>
                                    </ContextAwareToggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0" style={{backgroundColor:'#164B2E'}}>
                                    <Card.Body className={classes.cardBody}>
                                        <Link to={'/manage_user'}
                                         className={activeLink === 'Configuration' ? classes.active : ''}
                                        >Manage User</Link><br/>
                                        <Link to={'/manage_role'}
                                        className={activeLink === 'Configuration' ? classes.active : ''}
                                        >Manage Role</Link><br/>
                                        <Link to={'/mdas'}
                                        className={activeLink === 'Configuration' ? classes.active : ''}
                                        >Manage MDAs</Link><br/>
                                        <Link to={'/manage_executives'}
                                        className={activeLink === 'Configuration' ? classes.active : ''}
                                        >Manage Executives</Link><br/>
                                    </Card.Body>
                                    
                                </Accordion.Collapse>
                        </Card>
                        )}
                        {/* <Card className={`${classes.accordionCard} ${activeLink === 'Configuration' ? classes.active : ''}`}>
                                <Card.Header className={classes.cardHeader} onClick={toggleMenu2}>
                                    <ContextAwareToggle  eventKey="1">
                                        <p className={classes.padding}>
                                            <span><img src={messageIcon} alt='icon' />Manage News</span>
                                            {isMenuOpen2 ?  (<i class='bx bx-chevron-up'></i>): (<i class='bx bx-chevron-down'></i>)} 
                                        </p>
                                    </ContextAwareToggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="1" style={{backgroundColor:'#164B2E'}}>
                                    <Card.Body className={classes.cardBody}>
                                        <Link to={'/news_editor'}
                                         className={activeLink === 'Configuration' ? classes.active : ''}
                                        >General News</Link><br/>

                                        <Link to={'/departmental_news'}
                                        className={activeLink === 'DepartmentalNews' ? classes.active : ''}
                                        >Departmental News</Link><br/>

                                    </Card.Body>
                                    
                                </Accordion.Collapse>
                        </Card> */}

                        {/* <Card className={`${classes.accordionCard} ${activeLink === 'Manage News' ? classes.active : ''}`}>
                                <Card.Header className={classes.cardHeader} onClick={toggleMenu2}>
                                    <ContextAwareToggle  eventKey="1">
                                        <p className={classes.padding}>
                                            <span><img src={messageIcon} alt='icon' /> Manage News</span>
                                            {isMenuOpen2 ?  (<i class='bx bx-chevron-up'></i>): (<i class='bx bx-chevron-down'></i>)} 
                                        </p>
                                    </ContextAwareToggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="1" style={{backgroundColor:'#164B2E'}}>
                                    <Card.Body className={classes.cardBody}>
                                        <Link to={'/news_editor'}
                                         className={activeLink === 'Configuration' ? classes.active : ''}
                                        >Manage User</Link><br/>

                                        <Link to={'/manage_role'}
                                        className={activeLink === 'Configuration' ? classes.active : ''}
                                        >Manage Role</Link><br/>

                                       
                                    </Card.Body>
                                    
                                </Accordion.Collapse>
                        </Card> */}

                        </Accordion>
                        <Link
                            to={'/news_editor'}
                            className={activeLink === 'NewsEditor' ? classes.active : ''}
                        >
                            <p> <img src={messageIcon} alt='icon' /> Manage News </p>
                        </Link>

                        <Link
                            to={'/media'}
                            className={activeLink === 'Media' ? classes.active : ''}
                        >
                            <p> <img src={messageIcon} alt='icon' /> Videos</p>
                        </Link>
                        {/* <Link
                            to={'/mdas'}
                            className={activeLink === 'MDAs' ? classes.active : ''}
                        >
                            <p> <img src={messageIcon} alt='icon' /> Media</p>
                        </Link> */}
                        <Link
                            to={'/gallery'}
                            className={activeLink === 'Gallery' ? classes.active : ''}
                        >
                            <p> <img src={messageIcon} alt='icon' /> Photo Gallery</p>
                        </Link>
                        <Link
                            to={'/contract'}
                            className={activeLink === 'Contract' ? classes.active : ''}
                        >
                            <p> <img src={messageIcon} alt='icon' /> Public Documents</p>
                        </Link>
                        
                        <Link
                            to={'/faqs'}
                            className={activeLink === 'FAQs' ? classes.active : ''}
                        >
                            <p> <img src={messageIcon} alt='icon' />Manage FAQs</p>
                        </Link>
                        <Link
                            to={'/mda_service'}
                            className={activeLink === 'MDAService' ? classes.active : ''}
                        >
                            <p> <img src={messageIcon} alt='icon' />  MDA Services</p>
                        </Link>
                        <Link
                            to={'/profile'}
                            className={activeLink === 'Profile' ? classes.active : ''}
                        >
                            <p> <img src={UserLogo} alt='icon' /> Change Password</p>
                        </Link>
                        
                        <Link
                            to={'/'}
                            className={activeLink === 'Logout' ? classes.active : ''}
                        >
                            <p> <img src={LogOutIcon} alt='icon' /> Logout</p>
                        </Link>
                       

                        {/* <Link
                            to={'/loan'}
                            className={activeLink === 'Loan' ? classes.active : ''}
                        >
                            <p><img src={messageIcon} alt='icon' /> Loans</p>
                        </Link>
                        <Link
                            to={'/grants'}
                            className={activeLink === 'Grants' ? classes.active : ''}
                        >
                            <p> <img src={messageIcon} alt='icon' /> Grants</p>
                        </Link>
                        <Link
                            to={'/invoice_onboard'}
                            className={activeLink === 'Invoices' ? classes.active : ''}
                        >
                            <p> <img src={Invoice} alt='icon' /> Invoices</p>
                        </Link>
    */}

                        {/* <Link
                            onClick={handleLogout}
                            // to={'/sign_in'}
                            className={activeLink === 'Logout' ? classes.active : ''}
                        >
                            <p>
                                <img src={LogOutIcon} alt='icon' />{' '}
                                {loading ? (
                                    <>
                                        <Spinner size='sm' style={{ marginRight: 5 }} /> Signing out...
                                    </>
                                ) : (
                                    'Log out'
                                )}
                            </p>
                        </Link> */}
                    </div>

                </div>
                <div className={classes.harmborgers} onClick={toggleMenu}>
                {isMenuOpen ? (
                  <span className={classes.closs}>
                    <i className='bx bx-x'></i>
                  </span>
                ) : (
                  <span className={classes.open}>
                    <i className='bx bx-menu'></i>
                  </span>
                )}
              </div>
            </div>
            {/* <div className={classes.formSection}>
                <div className={classes.formSectionHeader}>
                    <h3>News Editor</h3>
                    
                </div>
                <div className={classes.formSectionHeader}>
                    <p>Welcome </p>
                    <h3 style={{color:'#2D995F'}}>{user}</h3>
                </div>
            </div> */}
        </div>
        // </div>
    )
}
