import React, { useState, useEffect, useRef } from 'react';
import classes from './CreateMDAs.module.css';
import RegLogo from '../../Images/RegistrationLogo.svg'
import { Spinner, Badge, Button } from 'react-bootstrap';
import Folder from '../../Images/folder-2.svg';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import ArrowLogo from '../../Images/arrow-left.svg';
import LoanImage from '../../Images/loan bg.svg';
import MainDashoard from '../Main Dashboard/MainDashoard';
import Ready from '../../Images/nothing.svg'
import Ready1 from '../../Images/review.svg';
import { Link, useNavigate } from 'react-router-dom'
import Table from 'react-bootstrap/Table';
import placeHolder from "../../assets/imagePlaceHolder.jpg"
// import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import InputGroup from 'react-bootstrap/InputGroup';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // import styles
import './CustomQuillToolbar.css';
import { BASE_URL } from '../Api/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function CreateMDAs() {
    const navigate = useNavigate();
    const [editorHtml, setEditorHtml] = useState('');
    const [inputText, setInputText] = useState('');
    const [imageSrc, setImageSrc] = useState([]);
    const fileInputRef = useRef(null);
    const [bearer, setBearer] = useState('');
    const [user, setUser] = useState('');
    const [loading, setLoading] = useState(false);
    const [banner, setBanner] = useState('');
    const [selectedImage, setSelectedImage] = useState(null);
    const [newsTitle, setNewsTitle] = useState('');
    const [shortDescription, setShortDescription] = useState('');
    const [longDescription, setLongDescription] = useState('');
    const [newsCategory, setNewsCategory] = useState('');

    const [selectedType, setSelectedType] = useState("");
    const [specialAdviser, setSpecialAdviser] = useState("");
    const [generalManager, setGeneralManager] = useState("");
    const [description, setDescription] = useState("");
    const [commissionersName, setCommissionersName] = useState("");
    const [permanentSecretary, setPermanentSecretary] = useState("");
    const [commissionersProfile, setCommissionersProfile] = useState("");
    const [specialAdviserProfile, setSpecialAdviserProfile] = useState("");
    const [permanentSecretaryProfile, setPermanentSecretaryProfile] = useState("");
    const [generalManagerProfile, setGeneralManagerProfile] = useState("");
    const [mission, setMission] = useState("");
    const [vision, setVision] = useState("");
    const [aboutMda, setAboutMda] = useState("");
    const [officeAddress, setOfficeAddress] = useState("");
    const [phone, setPhone] = useState("");
    const [phone1, setPhone1] = useState("");
    const [order, setOrder] = useState("");
    const [selectedCommissionerPhoto, setSelectedCommissionersPhoto] = useState(null);
    const [selectedPermanentSecretaryPhoto, setSelectedPermanentSecretaryPhoto] = useState(null);
 

    const readData = async () => {
        try {
          const details = await AsyncStorage.getItem('userToken');
          const users = await AsyncStorage.getItem('fullName');
                 
          if (details !== null) {
            setBearer(details);
          }
          if (users !== null) {
            setUser(users);
          }
    
        } catch (e) {
          alert('Failed to fetch the input from storage');
        }
      };
    
      useEffect(() => {
        readData();
      }, []);
    


    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${bearer}`
      };

      console.log("All Details here:", description, selectedType, mission, vision, aboutMda, officeAddress, phone, phone1, commissionersName, commissionersProfile, selectedCommissionerPhoto)
      const createMDA = async () => {
        setLoading(true);
        try {
          const formData = new FormData();
          formData.append('name', description);
          formData.append('mda_type', selectedType);
          formData.append('mission', mission);
          formData.append('vision', vision);
          formData.append('about_mda', aboutMda);
          formData.append('office_address', officeAddress);
          formData.append('phone', phone);
          formData.append('phone1', phone1);
          formData.append('display_order', order);
          formData.append('commisioners_name', commissionersName);
          formData.append('commisioners_profile', commissionersProfile);
          formData.append('special_adviser', specialAdviser);
          formData.append('general_manager', generalManager);
          formData.append('special_adviser_profile', specialAdviserProfile);
          formData.append('general_manager_profile', generalManagerProfile);
          formData.append('permanent_secretary', permanentSecretary);
          formData.append('permanent_secretary_profile', permanentSecretaryProfile);
          if (selectedCommissionerPhoto) {
            formData.append('commisioners_image', selectedCommissionerPhoto);
        }
          if (selectedPermanentSecretaryPhoto) {
            formData.append('permanent_secretary_image', selectedPermanentSecretaryPhoto);
        }
     
          const response = await axios.post( `${BASE_URL}/create-mda`,
            formData,
            {
              headers: {
                ...headers,
                'Content-Type': 'multipart/form-data',
              },
            }
          );
          console.log(response);
        //   fetchMDAs();
        //   handleClose();
          setCommissionersName('');
          setDescription('');
          setGeneralManager('');
          setSpecialAdviser('');
          setCommissionersProfile('');
          setGeneralManagerProfile('');
          setSpecialAdviserProfile('');
          setPermanentSecretary('');
          setSelectedCommissionersPhoto(null);
          setSelectedPermanentSecretaryPhoto(null);
          setSelectedType('');
          navigate(-1);
          toast.success(response.data.message);
          console.log(response.data);
    
        } catch (error) {
          let errorMessage = 'An error occurred. Please try again.';
          if (error.response && error.response.data && error.response.data.message) {
              if (typeof error.response.data.message === 'string') {
                  errorMessage = error.response.data.message;
              } else if (Array.isArray(error.response.data.message)) {
                  errorMessage = error.response.data.message.join('; ');
              } else if (typeof error.response.data.message === 'object') {
                  errorMessage = JSON.stringify(error.response.data.message);
              }
          }
          toast.error(errorMessage);
          console.log(errorMessage);
      } finally {
        setLoading(false);
      }
};
      

      

      const handleImageChange = (event) => {
        const file = event.target.files[0]; 
        setSelectedCommissionersPhoto(file); 
    };

      const handleImageChange1 = (event) => {
        const file = event.target.files[0]; 
        setSelectedPermanentSecretaryPhoto(file); 
    };

    const handleEditorChange = (content, delta, source, editor) => {
    setEditorHtml(content);
  };

  const handleInputChange = (event) => {
    setInputText(event.target.value);
  };
    
  const applyInputText = () => {
    // You can add logic to format the text input as needed
    setEditorHtml(editorHtml + inputText);
    setInputText('');
  };

  

    const handleGodBack = () =>{
        navigate('/mdas')
    }
    const openNews = () =>{
        navigate('/subnew')
    }

    

    //   const handleImageChange = (event) => {
    //     const file = event.target.files[0];
    //     if (file && (file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'image/jpg')) {
    //       const reader = new FileReader();
    //       reader.onloadend = () => {
    //         setImageSrc(reader.result);
    //       };
    //       reader.readAsDataURL(file);
    //     } else {
    //       alert('Only *.png, *.jpg and *.jpeg image files are accepted.');
    //       setImageSrc(placeHolder)
    //     }
    //   };
//     const handleImageChange = (event) => {
//         const files = event.target.files;
//         imageSrc(Array.from(files));
//       };
        const handleTypeChange = (event) => {
            setSelectedType(event.target.value);
        };
        // const handleImageChange = (event) => {
        //     const files = event.target.files;
        //     setImageSrc(Array.from(files));
        //     setSelectedImage(URL.createObjectURL(files[0]));
        // };

        console.log(selectedImage);
    

    return (
        <div>
          <ToastContainer />
        <MainDashoard />

        <div className={classes.finishedbodyCont}>
            <div className={classes.topPadding}>
                <div className={`${classes.formSecCont}`}>
                    <div className={classes.formSectionHeader}>
                        <h3>MDAs</h3>
                    </div>
                    <div className={classes.formSectionHeader}>
                        {/* <small>Welcome </small> */}
                        <h3 style={{color:'#2D995F'}}>{user}</h3>
                    </div>
                </div>
            </div>
            
        <div className={classes.mainform}>

    <div className={classes.loandgrantcards}>
        <div className={classes.loandethead}>
            <div className={classes.formLabel}>
                <h4>Add New</h4>
                <p className={classes.loanText}>Fill in the details below to add new mda...</p>
            </div>
            {/* <div className={classes.formIntBtn}>
                <Button variant="light" className={classes.btn1}> Add Member</Button>
                <Button variant="success" className={classes.btn2}>New Campaign</Button>
            </div> */}
        </div>

        <div className={classes.loanContainer}>
            <div className={classes.loanResponsive}>
                
                <div className={classes.mainForm}>
                    {/* <h5>General</h5> */}
                    <Form className={classes.form}>
                        <div className={classes.formGrid}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>MDA Type<span className={classes.span}>*</span></Form.Label>
                                <Form.Select
                                className="form-control"
                                as="select"
                                value={selectedType}
                                onChange={handleTypeChange}
                                >
                                <option value="" disabled>Select Type</option>
                                <option value="1">Ministry</option>
                                <option value="2">Department</option>
                                <option value="3">Agencies</option>

                                </Form.Select>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Description<span className={classes.span}>*</span></Form.Label>
                                <Form.Control
                                type="text"
                                placeholder="Enter the Name of the MDA"
                                // autoFocus
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>{selectedType === "1" 
  ? "Commissioner's Name" 
  : selectedType === "2" 
  ? "Head of Department's Name" 
  : selectedType === "3" 
  ? "Head of Agency's Name" 
  : "Commissioner's Name"
}<span className={classes.span}>*</span></Form.Label>
                                <Form.Control
                                type="text"
                                placeholder={selectedType === "1" 
                                    ? "Enter Commissioner's Name" 
                                    : selectedType === "2" 
                                    ? "Enter Head of Department's Name" 
                                    : selectedType === "3" 
                                    ? "Enter Head of Agency's Name" 
                                    : "Enter Commissioner's Name"
                                  }
                                // autoFocus
                                value={commissionersName}
                                onChange={(e) => setCommissionersName(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Mission Statement<span className={classes.span}>*</span></Form.Label>
                                <Form.Control
                                type="text"
                                placeholder="Enter Mission Statement"
                                // autoFocus
                                value={mission}
                                onChange={(e) => setMission(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Special Adviser's Name<span className={classes.span}>*</span></Form.Label>
                                <Form.Control
                                type="text"
                                placeholder="Enter Special Adviser's Name"
                                // autoFocus
                                value={specialAdviser}
                                onChange={(e) => setSpecialAdviser(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>General Manager's Name</Form.Label>
                                <Form.Control
                                type="text"
                                placeholder="Enter General Manager's Name"
                                // autoFocus
                                value={generalManager}
                                onChange={(e) => setGeneralManager(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Vission Statement</Form.Label>
                                <Form.Control
                                type="text"
                                placeholder="Enter Vision Statement"
                                // autoFocus
                                value={vision}
                                onChange={(e) => setVision(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>About MDA<span className={classes.span}>*</span></Form.Label>
                                <Form.Control
                                type="text"
                                placeholder="Enter About MDA"
                                // autoFocus
                                value={aboutMda}
                                onChange={(e) => setAboutMda(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Office Address<span className={classes.span}>*</span></Form.Label>
                                <Form.Control
                                type="text"
                                placeholder="Enter Office Address"
                                // autoFocus
                                value={officeAddress}
                                onChange={(e) => setOfficeAddress(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Phone Number 1<span className={classes.span}>*</span></Form.Label>
                                <Form.Control
                                type="text"
                                placeholder="Enter Phone1"
                                // autoFocus
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Phone Number 2<span className={classes.span}>*</span></Form.Label>
                                <Form.Control
                                type="text"
                                placeholder="Enter Phone2"
                                // autoFocus
                                value={phone1}
                                onChange={(e) => setPhone1(e.target.value)}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>{selectedType === "1" 
  ? "Commissioner's Photo" 
  : selectedType === "2" 
  ? "Head of Department's Photo" 
  : selectedType === "3" 
  ? "Head of Agency's Photo" 
  : "Commissioner's Photo"
}</Form.Label>
                                <Form.Control
                                type="file"
                                placeholder="Select photo"
                                // autoFocus
                                accept=".jpg, .jpeg, .png"
                                onChange={handleImageChange}
                                name="image"
                                />
                            </Form.Group>


                            {/* <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Short Description<span className={classes.span}>*</span></Form.Label>
                                <Form.Control type="text" placeholder="News Category"
                                value={shortDescription}
                                onChange={handleShortDescriptionChange}
                                />
                            </Form.Group> */}

                            <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>{selectedType === "1" 
  ? "Commissioner's Profile" 
  : selectedType === "2" 
  ? "Head of Department's Profile" 
  : selectedType === "3" 
  ? "Head of Agency's Profile" 
  : "Commissioner's Profile"
}<span className={classes.span}>*</span></Form.Label>
                                <Form.Control
                                as="textarea"
                                rows={3}
                                placeholder={selectedType === "1" 
                                    ? "Enter Commissioner's Profile" 
                                    : selectedType === "2" 
                                    ? "Enter Head of Department's Profile" 
                                    : selectedType === "3" 
                                    ? "Enter Head of Agency's Profile" 
                                    : "Enter Commissioner's Profile"
                                  }
                                value={commissionersProfile}
                                onChange={(e) => setCommissionersProfile(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Display Order</Form.Label>
                                <Form.Control
                                type="text"
                                placeholder="Enter the order it will display on the website"
                                // autoFocus
                                value={order}
                                onChange={(e) => setOrder(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Special Adviser's Profile<span className={classes.span}>*</span></Form.Label>
                                <Form.Control
                                as="textarea"
                                rows={3}
                                placeholder='Enter SA Profile'
                                value={specialAdviserProfile}
                                onChange={(e) => setSpecialAdviserProfile(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>General Manger's Profile<span className={classes.span}>*</span></Form.Label>
                                <Form.Control
                                as="textarea"
                                rows={3}
                                placeholder='Enter General Manager Profile'
                                value={generalManagerProfile}
                                onChange={(e) => setGeneralManagerProfile(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Permanent Secretary's Name</Form.Label>
                                <Form.Control
                                type="text"
                                placeholder="Enter Permanent Secretary Name"
                                // autoFocus
                                value={permanentSecretary}
                                onChange={(e) => setPermanentSecretary(e.target.value)}
                                />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Permanent Secretary's Photo</Form.Label>
                                <Form.Control
                                type="file"
                                placeholder="Select photo"
                                // autoFocus
                                accept=".jpg, .jpeg, .png"
                                onChange={handleImageChange1}
                                name="image"
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Permanent Secretary's Profile<span className={classes.span}>*</span></Form.Label>
                                <Form.Control
                                as="textarea"
                                rows={3}
                                placeholder='Enter Perm. Sec. Profile'
                                value={permanentSecretary}
                                onChange={(e) => setPermanentSecretary(e.target.value)}
                                />
                            </Form.Group>
                        </div>

                    </Form>

                
                </div>
                {/* <div className={classes.banner}>
                    <h5>Banner</h5>
                    <div className={classes.imgSec}>
                        <div className={classes.imgCont}>
                            <div className={classes.mainImgCont}>
                                <>
                                {selectedImage && <img src={selectedImage} alt='image' />}
                                </>
                            
                            </div>
                        </div>
                        <p>Set the product thumbnail image. Only *.png, *.jpg and *.jpeg image files are accepted</p>
                        <div className={classes.imgInput}>
                            <input
                            required=""
                            type="file"
                            accept=".jpg, .jpeg, .png"
                            onChange={handleImageChange}
                            name="image"
                          />
                        </div>
                    </div>
                </div>     */}
            </div>
            <div className={`${classes.formIntBtn} ${classes.formIntBtn2}`}>
                <Button variant="light" className={classes.btn1} onClick={handleGodBack}> Cancel</Button>
                <Button disabled={loading} variant='success'  className={classes.btn2} onClick={() => createMDA()}>
                    {loading ? (
                        <>
                            <Spinner size='sm' />
                            <span style={{ marginLeft: '5px' }}>Creating MDA, Please wait...</span>
                        </>
                    ) : (
                        "Create MDA"
                    )}
                </Button>
            </div>
            {/* <div className={classes.footerCont}>
                <div>
                    <small>2024© <Link to={'#'}> Ogun State</Link></small>
                </div>
                <div>
                    <small className={classes.small}>
                        <Link to={'#'}>About</Link>
                        <Link to={'#'}>Support</Link>
                        <Link to={'#'}>Purchase</Link>
                    </small>
                </div>
            </div> */}
        </div>
    </div> 

</div>

        </div>

    </div>
)
}