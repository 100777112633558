import React, { useState, useEffect } from 'react';
import classes from './NewsEditor.module.css';
import RegLogo from '../../Images/RegistrationLogo.svg'
import { Spinner, Badge, Button } from 'react-bootstrap';
import Folder from '../../Images/folder-2.svg';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import ArrowLogo from '../../Images/arrow-left.svg';
import LoanImage from '../../Images/loan bg.svg';
import MainDashoard from '../Main Dashboard/MainDashoard';
import Ready from '../../Images/nothing.svg'
import Ready1 from '../../Images/review.svg';
import { Link, useNavigate } from 'react-router-dom'
import Table from 'react-bootstrap/Table';
import { BASE_URL } from '../Api/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';


function ApprovalBadge({ status }) {
  let badgeProps = {};

  switch (status) {
      case "0":
          badgeProps = { text: "Pending", variant: "warning" };
          break;
      case "1":
          badgeProps = { text: "Awaiting Final Approval", variant: "info" };
          break;
      case "2":
          badgeProps = { text: "Approved", variant: "success" };
          break;
      case "3":
          badgeProps = { text: "Disapproved", variant: "danger" };
          break;
      default:
          badgeProps = { text: "Unknown", variant: "secondary" };
          break;
  }

  return <Badge bg={badgeProps.variant}>{badgeProps.text}</Badge>;
}


export default function NewsEditor() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [loadings, setLoadings] = useState(false);
    const [editClicked, setEditClicked] = useState(false);
    const [viewClicked, setViewClicked] = useState(false);
    const [bearer, setBearer] = useState('');
    const [user, setUser] = useState('');
    const [allNews, setAllNews] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [trashClicked, setTrashClicked] = useState(false);
    const [totalEntries, setTotalEntries] = useState("");
    const [tableData, setTableData] = useState([]);
    const [roleId, setRoleId] = useState('');


    const readData = async () => {
        try {
          const details = await AsyncStorage.getItem('userToken');
          const users = await AsyncStorage.getItem('fullName');
          const detailss = await AsyncStorage.getItem('roleId');
                 
          if (details !== null) {
            setBearer(details);
          }
          if (users !== null) {
            setUser(users);
          }

          if (detailss !== null) {
            setRoleId(detailss);
        }
    
        } catch (e) {
          alert('Failed to fetch the input from storage');
        }
      };
    
      useEffect(() => {
        readData();
      }, []);
    


    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${bearer}`
      };

    const EditNews = () =>{
        navigate('/edit_news')
    } 
    const addNews = () =>{
        navigate('/create_news')
    }

    const fetchAllNews = async () => {
            setLoading(true);
        try {
          const response = await axios.get(`${BASE_URL}/get-news?page=${currentPage}`, { headers });
          const result = response.data?.data?.data;
        const resultx = response.data?.data?.total;
        setTotalEntries(resultx);
        setTableData(result);
        const total = response.data?.data?.last_page || 1;
        setTotalPages(total);
        console.log("Total:",tableData);
        } catch (error) {
          if (error.response && error.response.status === 401) {
            // Redirect to login page if unauthorized
            navigate('/login');
          } else {
          const errorStatus = error.response?.data?.message;
          console.log(errorStatus);
          setTableData([]);
        }
        } finally {
            setLoading(false);
        }
      };

    
    useEffect(() => {
        if (bearer) {
            fetchAllNews();
        }
      }, [bearer, currentPage]);

      const handleEditClick = (id) => {
        const foundNews = tableData.find(item => item.id === id);
         console.log(foundNews);
        navigate('/edit_news', { state: { selectedNews: foundNews } });
        setEditClicked(true);
      };
      const handleNewsCheck = (id) => {
        const foundNewss = tableData.find(item => item.id === id);
         console.log(foundNewss);
        navigate('/subnew', { state: { selectedNewss: foundNewss } });
        setViewClicked(true);
      };

      const handleTrashClick = async (id) => {
        try {
            const confirmed = await Swal.fire({
                title: 'Are you sure?',
                text: 'You are about to delete this news',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, cancel',
              });
      
          if (!confirmed.isConfirmed) {
            return; 
          }
      
          const response = await axios.get(`${BASE_URL}/news/delete?id=${id}`, { headers });
          fetchAllNews();
          toast.success(response.data.message);
        } catch (error) {
          let errorMessage = 'An error occurred. Please try again.';
          
          if (error.response?.data?.message) {
            if (typeof error.response.data.message === 'string') {
              errorMessage = error.response.data.message;
            } else if (Array.isArray(error.response.data.message)) {
              errorMessage = error.response.data.message.join('; ');
            } else if (typeof error.response.data.message === 'object') {
              errorMessage = JSON.stringify(error.response.data.message);
            }
          }
      
          toast.error(errorMessage);
        }
      };

    //   const handleNextPage = () => {
    //     if (currentPage < totalPages) {
    //         setCurrentPage(prevPage => prevPage + 1);
    //     }
    // };

    // const handlePrevPage = () => {
    //     if (currentPage > 1) {
    //         setCurrentPage(prevPage => prevPage - 1);
    //     }
    // };

    const handlePrevPage = () => {
        setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
      };
      
      const handleNextPage = () => {
        setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
      };

    // const EditNews = (id) => {
    //     // Implement your edit news logic here
    //     console.log(`Editing news with id: ${id}`);
    // };

    const renderApprovalBadge = (status) => <ApprovalBadge status={status} />;

    return (
        <div>
        <MainDashoard />

        <div className={classes.finishedbodyCont}>
            <div className={classes.topPadding}>
                <div className={`${classes.formSecCont}`}>
                    <div className={classes.formSectionHeader}>
                        <h3>Manage News</h3>
                    </div>
                    <div className={classes.formSectionHeader}>
                        {/* <small>Welcome </small> */}
                        <h3 style={{color:'#2D995F'}}>{user}</h3>
                    </div>
                </div>
            </div>
            

        <div className={classes.mainform}>

    <div className={classes.loandgrantcards}>
    {/* <div className={classes.loandethead}>
        <p className={classes.loanText}>Post news on the website from the editor</p>
        <Button variant="light" onClick={addNews}><i class='bx bx-plus-circle'></i> Add New</Button>
    </div> */}
    <div className={classes.loandethead}>
        <div className={classes.formLabel}>
            {/* <h4 style={{color:'#2D995F'}}>News</h4> */}
            {/* <p className={classes.loanText}>Home - Apps - eCommerce - Catalog</p> */}
        </div>
        <div className={classes.formIntBtn}>
            {/* <Button variant="light" className={classes.btn1}> Add Member</Button> */}
            <Button variant="success" onClick={addNews} className={classes.btn2}>Add New</Button>
        </div>
    </div>
    {loading? (
        <small>Fetching news...</small>
    ) : (
    <div className={classes.loanContainer}>
        <div className={classes.loanResponsive}>
        <Table striped bordered hover>
      <thead>
        <tr>
          <th>S/N</th>
          <th>TITLE</th>
          <th>DATE POSTED</th>
          <th>APPROVAL STATUS</th>
          <th>CREATED BY</th>
          <th>APPROVED/DISAPPROVED BY</th>
          {/* <th>DISAPPROVAL DESCRIPTION</th>
          <th>AUTHOR</th> */}
          <th>ACTION</th>
        </tr>
      </thead>
      <tbody>
      {tableData.map((item, index) => (
            <tr key={index}>
                <td>{index + 1}</td>
                <td className={classes.newsTitle}>{item.title}</td>
                <td>{new Date(item.created_date).toLocaleDateString()}</td>
                <td>{renderApprovalBadge(item.approval_status)}</td>
                <td>{item.created_by?.name}</td>
                <td>
  {item.approval_status === "2" 
    ? item.approved_by?.name 
    : item.approval_status === "3" 
    ? item.disapproved_by?.name 
    : null}
</td>
                {/* <td>{}</td> */}
                {/* <td>{item.registeredby || 'Unknown'}</td> */}
                <td className={classes.edit}>
                {(item.approval_status === "0" || item.approval_status === "3") && !["13", "14"].includes(roleId) ? (
    <i className='bx bx-edit-alt' onClick={() => handleEditClick(item.id)}></i>
) : null}

                    <i class='bx bx-show-alt' onClick={() => handleNewsCheck(item.id)}></i> 
                    <i class='bx bxs-trash' style={{ color: 'red' }} onClick={() => handleTrashClick(item.id)}></i>
                </td>
            </tr>
        ))}
      </tbody>
    </Table>
    <div className={classes.accrodBtns}>
        
        <Button variant='light' className={classes.prev} onClick={handlePrevPage} >Previous</Button>
        {/* <p style={{ color: '#2D995F' }}>{currentPage} of {totalPages}</p> */}
        <p style={{ color:'#2D995F'}}>{currentPage} of {totalPages}</p> 
        <Button variant="success" className={classes.next} onClick={handleNextPage} >Next</Button>
    </div>
    </div>
    </div>
     )}
</div> 

</div>

        </div>

    </div>
)
}