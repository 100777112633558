import React, { useState, useEffect, useRef } from 'react';
import classes from './NewGallery.module.css';
import RegLogo from '../../Images/RegistrationLogo.svg'
import { Spinner, Badge, Button } from 'react-bootstrap';
import Folder from '../../Images/folder-2.svg';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import ArrowLogo from '../../Images/arrow-left.svg';
import LoanImage from '../../Images/loan bg.svg';
import MainDashoard from '../Main Dashboard/MainDashoard';
import Ready from '../../Images/nothing.svg'
import Ready1 from '../../Images/review.svg';
import { Link, useNavigate } from 'react-router-dom'
import Table from 'react-bootstrap/Table';
import placeHolder from "../../assets/imagePlaceHolder.jpg"
// import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import InputGroup from 'react-bootstrap/InputGroup';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // import styles
import './CustomQuillToolbar.css';
import { BASE_URL } from '../Api/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function NewGallery() {
    const navigate = useNavigate();
    const [editorHtml, setEditorHtml] = useState('');
    const [inputText, setInputText] = useState('');
    const [imageSrc, setImageSrc] = useState([]);
    const fileInputRef = useRef(null);
    const [bearer, setBearer] = useState('');
    const [user, setUser] = useState('');
    const [loading, setLoading] = useState(false);
    const [banner, setBanner] = useState('');
    const [selectedImage, setSelectedImage] = useState('');
    const [newsTitle, setNewsTitle] = useState('');
    const [fileLink, setFileLink] = useState('');
    const [shortDescription, setShortDescription] = useState('');
    const [newsCategory, setNewsCategory] = useState('');
    

    const readData = async () => {
        try {
          const details = await AsyncStorage.getItem('userToken');
          const users = await AsyncStorage.getItem('fullName');
                 
          if (details !== null) {
            setBearer(details);
          }
          if (users !== null) {
            setUser(users);
          }
    
        } catch (e) {
          alert('Failed to fetch the input from storage');
        }
      };
    
      useEffect(() => {
        readData();
      }, []);
    


    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${bearer}`
      };



    const createNews= async () => {
        setLoading(true);
        
        try {
          const formData = new FormData();
          // formData.append('type', newsCategory);
          formData.append('title', newsTitle);
          formData.append('description', shortDescription);
          selectedImage.forEach((file) => {
            formData.append('file_path', file);
        });
            const response = await axios.post(`${BASE_URL}/media/upload-photo`,
            formData,
            {
              headers: {
                ...headers,
                'Content-Type': 'multipart/form-data',
              },
            }
          );
          // console.log(newsTitle, shortDescription, fileLink, newsCategory,  "all here")
              console.log(response.data.message)
                // setImageSrc("");
                setNewsTitle("");
                setShortDescription("");
                setFileLink("");
                // setEditorHtml("");
                setNewsCategory("");
                navigate('/gallery');

                    toast.success(response.data.message);
                      console.log(response.data);
                  } catch (error) {
                      let errorMessage = 'An error occurred. Please try again.';
                      if (error.response && error.response.data && error.response.data.message) {
                          if (typeof error.response.data.message === 'string') {
                              errorMessage = error.response.data.message;
                          } else if (Array.isArray(error.response.data.message)) {
                              errorMessage = error.response.data.message.join('; ');
                          } else if (typeof error.response.data.message === 'object') {
                              errorMessage = JSON.stringify(error.response.data.message);
                          }
                      }
                      toast.error(errorMessage);
                      console.log(errorMessage);
                  } finally {
                      setLoading(false);
                  }
    };
      

      const handleTitleChange = (event) => {
        setNewsTitle(event.target.value);
      };
      const handleBannerChange = (event) => {
        setBanner(event.target.value);
      };
      const handleShortDescriptionChange = (event) => {
        setShortDescription(event.target.value);
      };
      const handleFileLinkChange = (event) => {
        setFileLink(event.target.value);
      };
      // const handleLongDescriptionChange = (event) => {
      //   setLongDescription(event.target.value);
      // };
      const handleCategoryChange = (event) => {
        setNewsCategory(event.target.value);
      };
    //   const handleBannerChange = (event) => {
    //     setBanner(event.target.value);
    //   };

    const handleEditorChange = (content, delta, source, editor) => {
    setEditorHtml(content);
  };

  const handleInputChange = (event) => {
    setInputText(event.target.value);
  };
    
  const applyInputText = () => {
    // You can add logic to format the text input as needed
    setEditorHtml(editorHtml + inputText);
    setInputText('');
  };

  const modules = {
    toolbar: [
    //   [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
      [{size: []}],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    //   [{'list': 'ordered'}, {'list': 'bullet'}, 
    //    {'indent': '-1'}, {'indent': '+1'}],
      [ 'image'],
    //   ['clean']                                        
    ],
  };

    const handleGodBack = () =>{
        navigate(-1);
    }
    const openNews = () =>{
        navigate('/subnew')
    }

    const handleImageChange = (event) => {
        const files = event.target.files;
        setSelectedImage(Array.from(files));
    };


    

    return (
        <div>
        <MainDashoard />

        <div className={classes.finishedbodyCont}>
            <div className={classes.topPadding}>
                <div className={`${classes.formSecCont}`}>
                    <div className={classes.formSectionHeader}>
                        <h3>Upload Photo Gallery</h3>
                    </div>
                    <div className={classes.formSectionHeader}>
                        {/* <small>Welcome </small> */}
                        <h3 style={{color:'#2D995F'}}>{user}</h3>
                    </div>
                </div>
            </div>
            
        <div className={classes.mainform}>

    <div className={classes.loandgrantcards}>
        <div className={classes.loandethead}>
            {/* <div className={classes.formLabel}>
                <h4>Add New</h4>
                <p className={classes.loanText}>Fill in the details below to add new news...</p>
            </div> */}
            {/* <div className={classes.formIntBtn}>
                <Button variant="light" className={classes.btn1}> Add Member</Button>
                <Button variant="success" className={classes.btn2}>New Campaign</Button>
            </div> */}
        </div>

        <div className={classes.loanContainer}>
            <div className={classes.loanResponsive}>
                
                <div className={classes.mainForm}>
                    {/* <h5>General</h5> */}
                    <Form className={classes.form}>

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Title<span className={classes.span}>*</span></Form.Label>
                            <Form.Control type="text" placeholder="Add Title"
                            value={newsTitle}
                            onChange={handleTitleChange}
                            />
                        </Form.Group>

                        
                        {/* <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Type<span className={classes.span}>*</span></Form.Label>
                            <Form.Select name="customer" className="form-control" required="" 
                            value={newsCategory}
                            onChange={handleCategoryChange}
                            >
                            <option value="">Select News Type</option>
                            <option value="1">General</option>
                            <option value="2">Department</option>
                                        
                         </Form.Select>
                            
                        </Form.Group> */}

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Description<span className={classes.span}>*</span></Form.Label>
                            <Form.Control type="text" placeholder="Enter Description"
                            value={shortDescription}
                            onChange={handleShortDescriptionChange}/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Choose File<span className={classes.span}>*</span></Form.Label>
                            <Form.Control 
                            type="file" 
                            placeholder="Choose file"
                            accept=".jpg, .jpeg, .png"
                            onChange={handleImageChange}
                            />
                            
                        </Form.Group>

                        {/* <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Long Description<span className={classes.span}>*</span></Form.Label>
                            <Form.Control type="text" placeholder="News Status"/>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Category<span className={classes.span}>*</span></Form.Label>
                            <Form.Control type="text" placeholder="Registration Name"/>
                        </Form.Group> */}

                        
                    </Form>

                    <div>
                        {/* <ReactQuill 
                            value={editorHtml}
                            onChange={handleEditorChange}
                            modules={modules}
                        /> */}
                        {/* <input 
                            type="text"
                            value={inputText}
                            onChange={handleInputChange}
                            placeholder="Enter text to format"
                        /> */}
                        {/* <button onClick={applyInputText}>Apply Text</button> */}
                    </div>
                </div>
               
            </div>
            <div className={`${classes.formIntBtn} ${classes.formIntBtn2}`}>
                <Button variant="light" className={classes.btn1} onClick={handleGodBack}> Cancel</Button>
                <Button variant="success" className={classes.btn2} onClick={createNews}>
                    {loading ? (
                        <>
                            <Spinner size='sm' />
                            <span style={{ marginLeft: '5px' }}>Creating, Please wait...</span>
                        </>
                    ) : (
                        "Upload Photo"
                    )}
                </Button>
            </div>
            {/* <div className={classes.footerCont}>
                <div>
                    <small>2024© <Link to={'#'}> Ogun State</Link></small>
                </div>
                <div>
                    <small className={classes.small}>
                        <Link to={'#'}>About</Link>
                        <Link to={'#'}>Support</Link>
                        <Link to={'#'}>Purchase</Link>
                    </small>
                </div>
            </div> */}
        </div>
    </div> 

</div>

        </div>

    </div>
)
}