import React, { useState, useEffect, useRef } from 'react';
import classes from '../Manage Profile/Profile.module.css'
import RegLogo from '../../Images/RegistrationLogo.svg'
import { Spinner, Badge, Button } from 'react-bootstrap';
import Folder from '../../Images/folder-2.svg';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import ArrowLogo from '../../Images/arrow-left.svg';
import LoanImage from '../../Images/loan bg.svg';
import MainDashoard from '../Main Dashboard/MainDashoard';
import Ready from '../../Images/nothing.svg'
import Ready1 from '../../Images/review.svg';
import { Link, useNavigate } from 'react-router-dom'
import Table from 'react-bootstrap/Table';
import videoPlaceHolder from "../../assets/VidPlaceHolder.mp4"
// import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import InputGroup from 'react-bootstrap/InputGroup';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // import styles
import './CustomQuillToolbar.css';
import pfp from '../../assets/pfp.png'
import changeIcon from "../../assets/changePhoto.png"
import { BASE_URL } from '../Api/Api';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';




export default function Profile() {
    const navigate = useNavigate();
    const [editorHtml, setEditorHtml] = useState('');
    const [inputText, setInputText] = useState('');
    const [isLoading, setIsLoading] = useState('');
    const [videoSrc, setVideoSrc] = useState(videoPlaceHolder);
    const fileInputRef = useRef(null);
    const [oldPassword, setOldPassword] = useState('');
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [bearer, setBearer] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showPassword1, setShowPassword1] = useState(false);

    const readData = async () => {
        try {
            const detail = await AsyncStorage.getItem('fullName');
            const details = await AsyncStorage.getItem('userToken');
            const detailss = await AsyncStorage.getItem('permissions');
            const admin = await AsyncStorage.getItem('admin');


           


            if (details !== null) {
                setBearer(details);
            }



           
        } catch (e) {
            alert('Failed to fetch the input from storage');
        }
    };

    useEffect(() => {
        readData();
    }, []);



    const handleOldChange = (event) => {
        setOldPassword(event.target.value);
    };
    const handleCurrentChange = (event) => {
        setCurrentPassword(event.target.value);
    };
    const handleNewChange = (event) => {
        setNewPassword(event.target.value);
    };

   
    const handleGodBack = () => {
        navigate(-1);
    }
   
    const handleChangePassword = async () => {
        setIsLoading(true);
        try {
            const responses = await axios.post(
                `${BASE_URL}/change-password`,
                {
                    new_password: currentPassword,
                    new_password_confirmation: newPassword,
                },
                {
                    headers: {
                        'Authorization': `Bearer ${bearer}`, 
                        'Content-Type': 'application/json',
                    }
                }
            );
            toast.success(responses.data.message);
            navigate(-1);
    
            setCurrentPassword('');
            setNewPassword('');
    
        } catch (error) {
            let errorMessage = 'An error occurred. Please try again.';
            if (error.response && error.response.data && error.response.data.message) {
                if (typeof error.response.data.message === 'string') {
                    errorMessage = error.response.data.message;
                } else if (Array.isArray(error.response.data.message)) {
                    errorMessage = error.response.data.message.join('; ');
                } else if (typeof error.response.data.message === 'object') {
                    errorMessage = JSON.stringify(error.response.data.message);
                }
            }
            toast.error(errorMessage);
            console.log(errorMessage);
        } finally {
            setIsLoading(false);
        }
    };
    

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };
    const toggleShowPassword1 = () => {
        setShowPassword1(!showPassword1);
    };

    return (
        <div>
            <MainDashoard />

            <div className={classes.finishedbodyCont}>
                <div className={classes.topPadding}>
                    <div className={`${classes.formSecCont}`}>
                        <div className={classes.formSectionHeader}>
                            <p className={classes.settingHeaderTxt}>Change your Password</p>
                            <p className={classes.underSettings}>Complete the following information to change your password succesfully</p>
                            
                        </div>
                        
                    </div>
                </div>

                <div className={classes.mainform}>

                    <div className={classes.loandgrantcards}>
                        <div className={classes.loandethead}>
                            <div className={classes.formLabel}>
                               

                            </div>
                            
                        </div>

                        <div className={classes.loanContainer}>
                            <div className={classes.loanResponsive}>
                               
                                <div className={classes.mainForm}>
                                    {/* <h5>General</h5> */}
                                    <div className={classes.line} />
                                    <div className={classes.beforeForm}>
                                        {/* <div>
                                            <p className={classes.perInfo}>Personal Information</p>
                                            <p className={classes.undrInfo}>Update your personal details here.</p>
                                            <Button className={classes.saveChange}>save changes</Button>
                                        </div> */}
                                        <Form className={classes.form}>

                                            {/* <div className={classes.inputFlex}>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>First name<span className={classes.span}>*</span></Form.Label>
                                                <Form.Control type="text" placeholder="New code" />
                                            </Form.Group>
                                            
                                             <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Last name<span className={classes.span}>*</span></Form.Label>
                                                <Form.Control type="text" placeholder="News Category" />
                                            </Form.Group>
                                            </div> */}

                                            {/* <div className={classes.inputFlex}>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Course<span className={classes.span}>*</span></Form.Label>
                                                <Form.Control type="text" placeholder="New code" />
                                            </Form.Group>

                                             <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Level<span className={classes.span}>*</span></Form.Label>
                                                <Form.Control type="text" placeholder="News Category" />
                                            </Form.Group>
                                            </div> */}

                                            

                                           

                                            {/* <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Old Password<span className={classes.span}>*</span></Form.Label>
                                                <Form.Control type="text" placeholder="Old Password" 
                                                value={oldPassword}
                                                onChange={handleOldChange}
                                                />
                                            </Form.Group> */}

                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>New Password<span className={classes.span}>*</span></Form.Label>
                                                <div className={classes.passwordInput}>
                                                    <Form.Control type={showPassword ? 'text' : 'password'} placeholder="Enter New Password"
                                                        value={currentPassword}
                                                        onChange={handleCurrentChange}
                                                    />
                                                    <FontAwesomeIcon 
                                                        icon={showPassword ? faEyeSlash : faEye} 
                                                        className={classes.eyeIcon} 
                                                        onClick={toggleShowPassword} 
                                                    />
                                                </div>
                                            </Form.Group>

                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Confirm Password<span className={classes.span}>*</span></Form.Label>
                                                <div className={classes.passwordInput}>
                                                    <Form.Control type={showPassword1 ? 'text' : 'password'} placeholder="Confirm New Password"
                                                        value={newPassword}
                                                        onChange={handleNewChange}
                                                    />
                                                    <FontAwesomeIcon 
                                                        icon={showPassword1 ? faEyeSlash : faEye} 
                                                        className={classes.eyeIcon} 
                                                        onClick={toggleShowPassword1} 
                                                    />
                                                </div>
                                            </Form.Group>

                                            {/* <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Long description</Form.Label>
            <ReactQuill 
                value={editorHtml}
                onChange={handleEditorChange}
                modules={modules}
                className={classes.editor}
                placeholder='Start writing your text here '
            />
        </Form.Group> */}
                                        </Form>
                                    </div>


                                    <div>
                                        {/* <ReactQuill 
                            value={editorHtml}
                            onChange={handleEditorChange}
                            modules={modules}
                        /> */}
                                        {/* <input 
                            type="text"
                            value={inputText}
                            onChange={handleInputChange}
                            placeholder="Enter text to format"
                        /> */}
                                        {/* <button onClick={applyInputText}>Apply Text</button> */}
                                    </div>
                                </div>
                            </div>
                            <div className={`${classes.formIntBtn} ${classes.formIntBtn2}`}>
                                <Button variant="light" className={classes.btn1} onClick={handleGodBack}> Cancel</Button>
                                <Button variant="success" className={classes.btn2} onClick={() => handleChangePassword()}>
                                {isLoading ? (
                        <>
                            <Spinner size='sm' />
                            <span style={{ marginLeft: '5px' }}>Processing, Please wait...</span>
                        </>
                    ) : (
                        "Save Changes"
                    )}
                                </Button>
                            </div>
                            {/* <div className={classes.footerCont}>
                <div>
                    <small>2024© <Link to={'#'}> Ogun State</Link></small>
                </div>
                <div>
                    <small className={classes.small}>
                        <Link to={'#'}>About</Link>
                        <Link to={'#'}>Support</Link>
                        <Link to={'#'}>Purchase</Link>
                    </small>
                </div>
            </div> */}
                        </div>
                    </div>

                </div>

            </div>

        </div>
    )
}