import React, { useState, useEffect } from 'react';
import classes from './SubNews.module.css';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import MainDashoard from '../Main Dashboard/MainDashoard';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { BASE_URL } from '../Api/Api';
import { Spinner, Button, Modal, Form } from 'react-bootstrap';

export default function SubNews() {
    const navigate = useNavigate();
    const [bearer, setBearer] = useState('');
    const [user, setUser] = useState('');
    const [reason, setReason] = useState('');
    const [roleId, setRoleId] = useState('');
    const location = useLocation();
    const { selectedNewss } = location.state || {};
    const [show, setShow] = useState(false);
    const [approvalLoading, setApprovalLoading] = useState(false);
    const [disapprove, setDisapprove] = useState(false);
console.log(selectedNewss);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [newsTitle, setNewsTitle] = useState(selectedNewss?.title);
    const [shortDescription, setShortDescription] = useState(selectedNewss?.short_desc);
    const [longDescription, setLongDescription] = useState(selectedNewss?.long_desc);
    const [selectedImage, setSelectedImage] = useState(selectedNewss?.banner);

    const readData = async () => {
        try {
            const detail = await AsyncStorage.getItem('fullName');
            const details = await AsyncStorage.getItem('userToken');
            const detailss = await AsyncStorage.getItem('roleId');

            if (detail !== null) {
                setUser(detail);
            }

            if (details !== null) {
                setBearer(details);
            }
            if (detailss !== null) {
                setRoleId(detailss);
            }
        } catch (e) {
            alert('Failed to fetch the input from storage');
        }
    };

    useEffect(() => {
        readData();
    }, []);

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${bearer}`
    };

    const goBack = () => {
        navigate('/news_editor');
    };

    const handleApproval = async () => {
        setApprovalLoading(true);
        try {
            const response = await axios.post(
                `${BASE_URL}/approve-news`,
                { id: selectedNewss.id },
                { headers }
            );
            navigate('/news_editor');
            toast.success(response.data.message);
        } catch (error) {
            let errorMessage = 'An error occurred. Please try again.';
            if (error.response && error.response.data && error.response.data.message) {
                if (typeof error.response.data.message === 'string') {
                    errorMessage = error.response.data.message;
                } else if (Array.isArray(error.response.data.message)) {
                    errorMessage = error.response.data.message.join('; ');
                } else if (typeof error.response.data.message === 'object') {
                    errorMessage = JSON.stringify(error.response.data.message);
                }
            }
            toast.error(errorMessage);
        } finally {
            setApprovalLoading(false);
        }
    };

    const handleDisapprove = async () => {
        setDisapprove(true);
        try {
            const response = await axios.post(
                `${BASE_URL}/disapprove-news`,
                { id: selectedNewss.id, 
                  reason: reason },
                { headers }
            );
            navigate('/news_editor');
            toast.success(response.data.message);
        } catch (error) {
            let errorMessage = 'An error occurred. Please try again.';
            if (error.response && error.response.data && error.response.data.message) {
                if (typeof error.response.data.message === 'string') {
                    errorMessage = error.response.data.message;
                } else if (Array.isArray(error.response.data.message)) {
                    errorMessage = error.response.data.message.join('; ');
                } else if (typeof error.response.data.message === 'object') {
                    errorMessage = JSON.stringify(error.response.data.message);
                }
            }
            toast.error(errorMessage);
            console.log(errorMessage);
        } finally {
            setDisapprove(false);
        }
    };

    const renderApprovalButtons = () => {
        if (selectedNewss.approval_status === "1" && roleId === "13") {
            return (
                <div className={classes.BottomButton}>
                    <div onClick={handleApproval} className={classes.btnApprove}>
                        <button>
                            {approvalLoading ? (
                                <>
                                    <Spinner size="sm" />
                                    <span style={{ marginLeft: '5px' }}>Approving, Please wait...</span>
                                </>
                            ) : (
                                "Approve News"
                            )}
                        </button>
                    </div>
                    <div className={classes.btnApprove1}>
                        <button onClick={handleShow}>Disapprove News</button>
                    </div>
                </div>
            );
        }
        return null;
    };

    const timestamp = selectedNewss?.created_date;
const dateObj = new Date(timestamp);

const options = { 
    day: 'numeric', 
    month: 'long', 
    year: 'numeric', 
    hour: 'numeric', 
    minute: 'numeric', 
    hour12: true 
};

const day = dateObj.getDate();
const dayWithSuffix = `${day}${(day % 10 === 1 && day !== 11) ? 'st' : (day % 10 === 2 && day !== 12) ? 'nd' : (day % 10 === 3 && day !== 13) ? 'rd' : 'th'}`;

const formattedDate = `${dayWithSuffix} ${dateObj.toLocaleDateString('en-US', { month: 'long', year: 'numeric' })}`;


const formattedTime = dateObj.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });


const formattedTimestamp = `${formattedDate}, ${formattedTime}`;
    
    return (
        <div>
            <ToastContainer />
            <MainDashoard />

            <div className={classes.finishedbodyCont}>
                <Modal show={show} onHide={handleClose} animation={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>Disapprove News</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form style={{ marginTop: 20 }}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Reason</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Reason for disapproving"
                                    value={reason}
                                    onChange={(e) => setReason(e.target.value)}
                                />
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={handleClose}>
                            Go back
                        </Button>
                        <Button variant="success" onClick={handleDisapprove}>
                            {disapprove ? (
                                <>
                                    <Spinner size="sm" />
                                    <span style={{ marginLeft: '5px' }}>Disapproving, Please wait...</span>
                                </>
                            ) : (
                                "Disapprove News"
                            )}
                        </Button>
                    </Modal.Footer>
                </Modal>

                <div className={classes.topPadding}>
                    <div className={classes.formSecCont}>
                        <div className={classes.formSectionHeader}>
                            <h3>News Editor</h3>
                        </div>
                        <div>
                            <h3 style={{ color: '#2D995F' }}>{user}</h3>
                        </div>
                    </div>
                </div>

                <div className={classes.mainform}>
                    <div className={classes.loandgrantcards}>
                        <div className={classes.loandethead} onClick={goBack}>
                            <Button variant='light'><i className='bx bx-arrow-back'></i> Go Back</Button>
                        </div>
                        <div className={classes.loanContainer}>
                            <h4>{newsTitle}</h4>
                            <p className={classes.firstP}>{shortDescription}</p>
                            <p style={{marginTop: -20}}>{formattedTimestamp}</p>
                            <img src={selectedImage} style={{ width: "100%", height: 400, objectFit: "contain" }} />
                            <p dangerouslySetInnerHTML={{ __html: longDescription }} className={classes.secondP}></p>
                            <div>
                                {selectedNewss.approval_status !== "2" && renderApprovalButtons()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
