import React, { useState, useEffect, useRef } from 'react';
import classes from './CreateExecutives.module.css';
import RegLogo from '../../Images/RegistrationLogo.svg'
import { Spinner, Badge, Button } from 'react-bootstrap';
import Folder from '../../Images/folder-2.svg';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import ArrowLogo from '../../Images/arrow-left.svg';
import LoanImage from '../../Images/loan bg.svg';
import MainDashoard from '../Main Dashboard/MainDashoard';
import Ready from '../../Images/nothing.svg'
import Ready1 from '../../Images/review.svg';
import { Link, useNavigate } from 'react-router-dom'
import Table from 'react-bootstrap/Table';
import placeHolder from "../../assets/imagePlaceHolder.jpg"
// import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import InputGroup from 'react-bootstrap/InputGroup';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // import styles
import './CustomQuillToolbar.css';
import { BASE_URL } from '../Api/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function CreateExecutives() {
    const navigate = useNavigate();
    const [editorHtml, setEditorHtml] = useState('');
    const [inputText, setInputText] = useState('');
    const [imageSrc, setImageSrc] = useState([]);
    const fileInputRef = useRef(null);
    const [bearer, setBearer] = useState('');
    const [user, setUser] = useState('');
    const [loading, setLoading] = useState(false);
    const [banner, setBanner] = useState('');
    const [selectedImage, setSelectedImage] = useState(null);
    const [newsTitle, setNewsTitle] = useState('');
    const [shortDescription, setShortDescription] = useState('');
    const [longDescription, setLongDescription] = useState('');
    const [newsCategory, setNewsCategory] = useState('');
    const [portfolio, setPortfolio] = useState('');

    const [selectedLevel, setSelectedLevel] = useState("");
    const [selectedType, setSelectedType] = useState("");
    const [description, setDescription] = useState("");
    const [commissionersName, setCommissionersName] = useState("");
    const [commissionersProfile, setCommissionersProfile] = useState("");
    const [mission, setMission] = useState("");
    const [vision, setVision] = useState("");
    const [aboutMda, setAboutMda] = useState("");
    const [officeAddress, setOfficeAddress] = useState("");
    const [phone, setPhone] = useState("");
    const [phone1, setPhone1] = useState("");
    const [order, setOrder] = useState("");
    const [selectedCommissionerPhoto, setSelectedCommissionersPhoto] = useState(null);
 

    const readData = async () => {
        try {
          const details = await AsyncStorage.getItem('userToken');
          const users = await AsyncStorage.getItem('fullName');
                 
          if (details !== null) {
            setBearer(details);
          }
          if (users !== null) {
            setUser(users);
          }
    
        } catch (e) {
          alert('Failed to fetch the input from storage');
        }
      };
    
      useEffect(() => {
        readData();
      }, []);
    


    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${bearer}`
      };

      console.log("All Details here:", description, selectedType, mission, vision, aboutMda, officeAddress, phone, phone1, commissionersName, commissionersProfile, selectedCommissionerPhoto)
      const createMDA = async () => {
        setLoading(true);
        try {
          const formData = new FormData();
          formData.append('commisioners_profile', commissionersProfile);
          formData.append('commisioners_name', commissionersName);
          formData.append('portfolio', portfolio);
          formData.append('description', description);
          selectedCommissionerPhoto.forEach((file) => {
            formData.append('commisioners_image', file);
          });
          const response = await axios.post( `${BASE_URL}/create-executive`,
            formData,
            {
              headers: {
                ...headers,
                'Content-Type': 'multipart/form-data',
              },
            }
          );
          console.log(response)
        //   fetchMDAs();
        //   handleClose();
          setCommissionersName('');
          setDescription('');
          setCommissionersProfile('');
          setSelectedCommissionersPhoto(null);
          setSelectedType('');
          navigate(-1);
          toast.success(response.data.message);
          console.log(response.data);
    
        } catch (error) {
          let errorMessage = 'An error occurred. Please try again.';
          if (error.response && error.response.data && error.response.data.message) {
            if (typeof error.response.data.message === 'string') {
              errorMessage = error.response.data.message;
            } else if (Array.isArray(error.response.data.message)) {
              errorMessage = error.response.data.message.join('; ');
            } else if (typeof error.response.data.message === 'object') {
              errorMessage = JSON.stringify(error.response.data.message);
            }
          }
          toast.error(errorMessage);
          console.log(errorMessage);
          setLoading(false);
        }
      };
      

      const handleImageChange = (event) => {
        const files = event.target.files;
        setSelectedCommissionersPhoto(Array.from(files));
      };

    const handleEditorChange = (content, delta, source, editor) => {
    setEditorHtml(content);
  };

  const handleInputChange = (event) => {
    setInputText(event.target.value);
  };
    
  const applyInputText = () => {
    // You can add logic to format the text input as needed
    setEditorHtml(editorHtml + inputText);
    setInputText('');
  };

  const modules = {
    toolbar: [
    //   [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
      [{size: []}],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    //   [{'list': 'ordered'}, {'list': 'bullet'}, 
    //    {'indent': '-1'}, {'indent': '+1'}],
      [ 'image'],
    //   ['clean']                                        
    ],
  };

    const handleGodBack = () =>{
        navigate(-1);
    }
    const openNews = () =>{
        navigate('/subnew')
    }

    

    //   const handleImageChange = (event) => {
    //     const file = event.target.files[0];
    //     if (file && (file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'image/jpg')) {
    //       const reader = new FileReader();
    //       reader.onloadend = () => {
    //         setImageSrc(reader.result);
    //       };
    //       reader.readAsDataURL(file);
    //     } else {
    //       alert('Only *.png, *.jpg and *.jpeg image files are accepted.');
    //       setImageSrc(placeHolder)
    //     }
    //   };
//     const handleImageChange = (event) => {
//         const files = event.target.files;
//         imageSrc(Array.from(files));
//       };
        const handleTypeChange = (event) => {
            setSelectedType(event.target.value);
        };
        const handleLevelChange = (event) => {
            setSelectedLevel(event.target.value);
        };
        // const handleImageChange = (event) => {
        //     const files = event.target.files;
        //     setImageSrc(Array.from(files));
        //     setSelectedImage(URL.createObjectURL(files[0]));
        // };

        console.log(selectedImage);
    

    return (
        <div>
        <MainDashoard />

        <div className={classes.finishedbodyCont}>
            <div className={classes.topPadding}>
                <div className={`${classes.formSecCont}`}>
                    <div className={classes.formSectionHeader}>
                        <h3>Create Executive</h3>
                    </div>
                    <div className={classes.formSectionHeader}>
                        {/* <small>Welcome </small> */}
                        <h3 style={{color:'#2D995F'}}>{user}</h3>
                    </div>
                </div>
            </div>
            
        <div className={classes.mainform}>

    <div className={classes.loandgrantcards}>
        <div className={classes.loandethead}>
            <div className={classes.formLabel}>
                {/* <h4>Add New</h4>
                <p className={classes.loanText}>Fill in the details below to add new news...</p> */}
            </div>
            {/* <div className={classes.formIntBtn}>
                <Button variant="light" className={classes.btn1}> Add Member</Button>
                <Button variant="success" className={classes.btn2}>New Campaign</Button>
            </div> */}
        </div>

        <div className={classes.loanContainer}>
            <div className={classes.loanResponsive}>
                
                <div className={classes.mainForm}>
                    {/* <h5>General</h5> */}
                    <Form className={classes.form}>
                        <div className={classes.formGrid}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Name<span className={classes.span}>*</span></Form.Label>
                                <Form.Control
                                type="text"
                                placeholder="Enter Executive's Name"
                                // autoFocus
                                value={commissionersName}
                                onChange={(e) => setCommissionersName(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Portfolio<span className={classes.span}>*</span></Form.Label>
                                <Form.Control
                                type="text"
                                placeholder="Enter Portfolio"
                                // autoFocus
                                value={portfolio}
                                onChange={(e) => setPortfolio(e.target.value)}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Level<span className={classes.span}>*</span></Form.Label>
                                <Form.Select
                                className="form-control"
                                as="select"
                                value={selectedLevel}
                                onChange={handleLevelChange}
                                >
                                <option value="" disabled>Select Level</option>
                                <option value="Level_1">Level 1</option>
                                <option value="Level_2">Level 2</option>
                                <option value="Level_3">Level 3</option>
                                <option value="Level_4">Level 4</option>
                              

                                </Form.Select>
                            </Form.Group>
                            
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Short Description<span className={classes.span}>*</span></Form.Label>
                                <Form.Control
                                type="text"
                                placeholder="Enter short description of executive"
                                // autoFocus
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                />
                            </Form.Group>

                           
                            
                            
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Passport Photograph<span className={classes.span}>*</span></Form.Label>
                                <Form.Control
                                type="file"
                                placeholder="Select photo"
                                // autoFocus
                                accept=".jpg, .jpeg, .png"
                                onChange={handleImageChange}
                                name="image"
                                />
                            </Form.Group>


                            <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Profile<span className={classes.span}>*</span></Form.Label>
                                <Form.Control
                                as="textarea"
                                rows={3}
                                placeholder="Enter Executive's Profile"
                                value={commissionersProfile}
                                onChange={(e) => setCommissionersProfile(e.target.value)}
                                />
                            </Form.Group>
                            
                        </div>

                    </Form>

                
                </div>
               
            </div>
            <div className={`${classes.formIntBtn} ${classes.formIntBtn2}`}>
                <Button variant="light" className={classes.btn1} onClick={handleGodBack}> Cancel</Button>
                <Button variant="success" className={classes.btn2} onClick={createMDA}>
                    {loading ? (
                        <>
                            <Spinner size='sm' />
                            <span style={{ marginLeft: '5px' }}>Creating Executive, Please wait...</span>
                        </>
                    ) : (
                        "Create Executive"
                    )}
                </Button>
            </div>
            {/* <div className={classes.footerCont}>
                <div>
                    <small>2024© <Link to={'#'}> Ogun State</Link></small>
                </div>
                <div>
                    <small className={classes.small}>
                        <Link to={'#'}>About</Link>
                        <Link to={'#'}>Support</Link>
                        <Link to={'#'}>Purchase</Link>
                    </small>
                </div>
            </div> */}
        </div>
    </div> 

</div>

        </div>

    </div>
)
}