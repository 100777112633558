import {  Route, Routes  } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './index.css';
import AsyncStorage from '@react-native-async-storage/async-storage';
import SignUp from './Web pages/Sign up/SignUp';
import SignIn from './Web pages/Sign in/SignIn';
import LandingPage from './Web pages/LandingPage/LandingPage';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Dashboard from './Web pages/Dashboard/Dashboard';

import CompleteReg from './Web pages/Complete Reg/CompleteReg';

// import { RegistrationProvider } from './Web pages/RegistrationContext';

// import GrantTable from './Web pages/News Editor/NewsEditor';
import NewsEditor from './Web pages/News Editor/NewsEditor';
import SubNews from './Web pages/Sub News/SubNews';
// import PubNews from './Web pages/Pub News/PubNews';
import CreateNews from './Web pages/CreateNews/CreateNews';
import MDAs from './Web pages/MDAAs/MDAAs';
import EditNews from './Web pages/EditNews/EditNews';
import Gallery from './Web pages/Gallery/Gallery';
import Media from './Web pages/Media/Media';
import NewMedia from './Web pages/AddNewMedia/NewMedia';
import NewGallery from './Web pages/AddNewGallery/NewGallery';
import EditGallery from './Web pages/EditGallery/EditGallery';
import EditMedia from './Web pages/EditMedia/EditMedia';
import ManageUser from './Web pages/Manage User/ManageUser';
import ManageRole from './Web pages/Manage Role/ManageRole';
import ManageMDAs from './Web pages/Manage MDAs/ManageMDAs';
import Settings from './Web pages/Manage Profile/Pofile'
import Profile from './Web pages/Manage Profile/Pofile';
import CreateRole from './Web pages/Create Role/CreateRole';
import ToggleSlider from './Web pages/Toggle Slider/ToggleSlider';
import ViewRole from './Web pages/Create Role/ViewRole';
import CreateContract from './Web pages/Contracts/CreateContract';
import Contracts from './Web pages/Contracts/Contracts';
import EditContract from './Web pages/Contracts/EditContract';
import ViewContract from './Web pages/Contracts/ViewContract';
import DepartmentalNews from './Web pages/DepartmentalNews/DepartentalNews';
import CreateDepartmentalNews from './Web pages/DepartmentalNews/CreateDepartmentalNews';
import EditDepartmentalNews from './Web pages/DepartmentalNews/EditDepartmentalNews';
import ViewDeptNews from './Web pages/DepartmentalNews/ViewDeptNews';
import ViewGallery from './Web pages/Sub News/ViewGallery';
import CreateMDAs from './Web pages/MDAAs/CreateMDAs';
import EditMDAs from './Web pages/MDAAs/EditMDAs';
import ViewMedia from './Web pages/Sub News/ViewMedia';
import FAQs from './Web pages/FAQs/FAQs';
import CreateFAQ from './Web pages/FAQs/CreateFAQs';
import EditFAQ from './Web pages/FAQs/EditFAQs';
import MdaService from './Web pages/MDA Service/MdaService';
import CreateMdaService from './Web pages/MDA Service/CreateMdaService';
import EditMdaService from './Web pages/MDA Service/EditMdaServic';
import ManageExecutives from './Web pages/Manage Executives/ManageExecutives';
import CreateExecutives from './Web pages/Manage Executives/CreateExecutives';
import EditExecutives from './Web pages/Manage Executives/EditExecutives';
import ForgotPassword from './Web pages/Sign in/ForgotPassword';
import ForgotPasswordReset from './Web pages/Forgot Password Reset/ForgotPasswordReset';


function App() {
  const [userIsInactive, setUserIsInactive] = useState(false);
  const inactivityThreshold = 300000; 
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

 let inactivityTimer;
  
 const resetInactivityTimer = () => {
   if (inactivityTimer) {
     clearTimeout(inactivityTimer);
   }
 
   inactivityTimer = setTimeout(async () => {
    await AsyncStorage.setItem('lastVisitedPage', location.pathname);
   
     setUserIsInactive(true);
    //  await AsyncStorage.clear();
     navigate('/login');
     
   }, inactivityThreshold);
 };
 
 const handleUserActivity = () => {
   resetInactivityTimer();
 };
 
 useEffect(() => {
   resetInactivityTimer();
 
   const activityEvents = ['mousemove', 'keydown', 'mousedown', 'touchstart'];
   activityEvents.forEach((event) => {
     document.addEventListener(event, handleUserActivity);
   });

   return () => {
    activityEvents.forEach((event) => {
      document.removeEventListener(event, handleUserActivity);
    });

    if (inactivityTimer) {
      clearTimeout(inactivityTimer);
    }
   };
 }, [location]);



  return (
    <>
      <Routes>
        <Route path='/'element={<SignIn/>}/>
        <Route path='/login'element={<SignIn/>}/>
        <Route path='/forgot_password'element={<ForgotPassword/>}/>
        <Route path='/reset_password'element={<ForgotPasswordReset/>}/>
        <Route path='/dashboard'element={<Dashboard/>}/>
        <Route path='/news_editor'element={<NewsEditor/>}/>
        <Route path='/my_profile'element={<CompleteReg/>}/>
        <Route path='/sign_in'element={<SignIn/>}/>
        <Route path='/subnew' element={<SubNews />} />
        <Route path='/view_gallery' element={<ViewGallery />} />
        <Route path='/view_media' element={<ViewMedia />} />
        <Route path='/create_news' element={<CreateNews/>}/>
        <Route path='/mdas' element={<MDAs/>}/>
        <Route path='/sign_up'element={<SignUp/>}/>
        <Route path='/edit_news'element={<EditNews/>}/>
        <Route path='/gallery'element={<Gallery/>}/>
        <Route path='/media'element={<Media/>}/>
        <Route path='/new_media'element={<NewMedia/>}/>
        <Route path='/settings'element={<Settings/>}/>
        <Route path='/new_gallery'element={<NewGallery/>}/>
        <Route path='/edit_gallery'element={<EditGallery/>}/>
        <Route path='/edit_media'element={<EditMedia/>}/>
        <Route path='/new_contract'element={<CreateContract/>}/>
        <Route path='/new_faq'element={<CreateFAQ/>}/>
        <Route path='/edit_contract'element={<EditContract/>}/>
        <Route path='/edit_faq'element={<EditFAQ/>}/>
        <Route path='/contract'element={<Contracts/>}/>
        <Route path='/manage_user'element={<ManageUser/>}/>
        <Route path='/manage_role'element={<ManageRole/>}/>
        <Route path='/manage_mdas'element={<ManageMDAs/>}/>
        <Route path='/manage_executives'element={<ManageExecutives/>}/>
        <Route path='/create_executives'element={<CreateExecutives />}/>
        <Route path='/edit_executives'element={<EditExecutives />}/>
        <Route path='/profile'element={<Profile/>}/>
        <Route path='/create_role'element={<CreateRole/>}/>
        <Route path='/view_role'element={<ViewRole/>}/>
        <Route path='/faqs'element={<FAQs/>}/>
        <Route path='/toggle_slider'element={<ToggleSlider/>}/>
        <Route path='/view_contract'element={<ViewContract/>}/>
        <Route path='/departmental_news'element={<DepartmentalNews/>}/>
        <Route path='/create_departmental_news'element={<CreateDepartmentalNews/>}/>
        <Route path='/edit_departmental_new'element={<EditDepartmentalNews/>}/>
        <Route path='/View_department_news'element={<ViewDeptNews/>}/>
        <Route path='/create_mdas'element={<CreateMDAs/>}/>
        <Route path='/edit_mdas'element={<EditMDAs/>}/>
        <Route path='/mda_service'element={<MdaService/>}/>
        <Route path='/create_mda_service'element={<CreateMdaService/>}/>
        <Route path='/edit_mda_service'element={<EditMdaService/>}/>
        
        </Routes>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        </>
  );
}

export default App;
